import {useNavigate, useLocation} from "react-router-dom";

const withNavigate = WrappedComponent => props => {
    let navigate = useNavigate();
    let location = useLocation();

    return (
        <WrappedComponent
            {...props}
            navigate={navigate}
            location={location}
        />
    )
}

export default withNavigate;
