import React from 'react';

import OpportunityList from "./OpportunityList";
import BaseApi from "../../utils/BaseApi";
import CommonForm from "../Shared/CommonForm";
import {CircularProgress} from "@mui/joy";


class OpportunityReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statuses: ['OPENX', ],
            team: [],
            my_roles: [],
            subscribers: null,
        }
    }

    async componentDidMount() {
        await this.retrieveSubscribers();
    }

    async retrieveSubscribers() {
        const url = 'subscriber/descendants/';
        const params = {
            // active: true,
        }
        const api = new BaseApi();
        let response = await api.get(
            url,
            params,
        );
        this.setState({
            subscribers: response.data.results,
        });
    }

    async processForm(form) {
        await this.setState({
            statuses: [],
            team: []
        })
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        await this.setState({
            statuses: formDataObj.statuses.split(','),
            team: formDataObj.team.split(','),
        });
    }

    render() {
        if (!this.state.subscribers) {
            return (
                <CircularProgress/>
            )
        }
        return (
            <>
                <CommonForm
                    processForm={this.processForm.bind(this)}
                    fields={[
                        {
                            name: 'statuses',
                            label: 'Статусы сделки',
                            xs: 12,
                            sm: null,
                            md: 6,
                            lg: null,
                            xl: null,
                            required: true,
                            default: ['OPENX',],
                            type: 'multiselect',
                            options: [
                                {'value': 'OPENX', 'label': 'В работе'},
                                {'value': 'DELAY', 'label': 'Ожидает'},
                                {'value': 'CLOSE', 'label': 'Закрыта'},
                                {'value': 'LOSTX', 'label': 'Потеряна'},
                            ]
                        },
                        {
                            'name': 'team',
                            'label': 'Участники команды',
                            'xs': 12,
                            'sm': null,
                            'md': 6,
                            'lg': null,
                            'xl': null,
                            'required': false,
                            'default': [],
                            'type': 'multiselect',
                            'options': this.state.subscribers.map((each) => {return(
                                {
                                    'value': each.id,
                                    'label': `${each.user.first_name} ${each.user.last_name}`
                                }
                            )})
                        },
                        {
                            'type': 'submit',
                            'label': 'Отобрать'
                        }
                    ]}
                />
                {this.state.statuses.map(each => {
                    return(
                        <OpportunityList status={each} team={this.state.team} myRoles={this.state.myRoles} />
                    )
                })}
            </>

        )
    }
}

export default OpportunityReport;