import React from "react";
import {Chip, Stack} from "@mui/joy";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Grid from "@mui/joy/Grid";
import Box from "@mui/joy/Box";
import Link from "@mui/joy/Link";


// components
import CommonEditModal from "./CommonEditModal";
import {generatePath} from "react-router-dom";


class CommonInfoTab extends React.Component {
    render() {
        return(
            <Stack>
                {this.props.groups.map((group) => (
                        <Card
                            sx={{
                                mb: 2
                            }}
                        >
                            <CardContent>
                                <Stack
                                    direction={'row'}
                                    spacing={2}
                                >
                                    {
                                        group.icon?
                                            group.icon:
                                            null
                                    }
                                    <Typography level="title-md">
                                        {group.title}
                                    </Typography>

                                </Stack>
                                <Grid
                                    container={true}
                                    spacing={2}
                                    sx={{
                                        pt: 2,
                                        flexGrow: 1
                                    }}
                                >
                                    {group.fields.map((field) => (
                                        <Grid
                                            xs={field.xs?field.xs:12}
                                            sm={field.sm?field.sm:12}
                                            md={field.md?field.md:6}
                                            lg={field.lg?field.lg:6}
                                        >
                                            <Card variant={'soft'}>

                                                <Typography level="body-xs">
                                                    {field.title}
                                                    {
                                                        field.edit && field.edit.permission?
                                                            <CommonEditModal
                                                                fields={field.edit.fields}
                                                                m2mFields={field.edit.m2mFields}
                                                                baseUrl={field.edit.baseUrl}
                                                                obj={field.edit.obj}
                                                                callBack={this.props.callBack}
                                                            />:
                                                            null
                                                    }
                                                </Typography>
                                                {
                                                    field.value===null || field.value===''?
                                                    <Chip
                                                        color="danger"
                                                    >
                                                        Не указано
                                                    </Chip>:
                                                        Array.isArray(field.value)?
                                                            <Stack
                                                                direction={'row'}
                                                                spacing={1}
                                                            >
                                                                {field.value.map((v) => (
                                                                    <Chip color={'primary'}>
                                                                        {v[field.fieldFromArray]}
                                                                    </Chip>
                                                                ))}
                                                            </Stack>:
                                                            <Box>
                                                                {
                                                                    field.html?
                                                                        <div dangerouslySetInnerHTML={{__html: field.value}} />:
                                                                        field.linkPattern?
                                                                            <Link
                                                                                href={
                                                                                    generatePath(field.linkPattern, { linkParam: field.linkParam})
                                                                                }
                                                                            >
                                                                                {field.value}
                                                                            </Link>:
                                                                                typeof field.value === 'boolean'?
                                                                                    field.value?<Chip color="success">Да</Chip>:<Chip color="danger">Нет</Chip>:
                                                                                    field.value

                                                                }
                                                            </Box>
                                                }
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </CardContent>
                        </Card>
                    )
                )}
            </Stack>
        )
    }
}

export default CommonInfoTab;
