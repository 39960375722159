import React from 'react';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import List from '@mui/joy/List';
import {Stack} from "@mui/joy";
import Link from "@mui/joy/Link";

// components
import withNavigate from '../../utils/withNavigate';
import Box from "@mui/joy/Box";


class CommonSidebar extends React.Component {
    render() {
        return (
            <Stack>
                {
                    this.props.groups.map((group) => (
                        <List
                            key={group.title}
                        >
                            <ListSubheader
                                role='presentation'
                                sx={{color: 'text.primary'}}
                            >
                                {group.title}
                            </ListSubheader>
                            {
                                group.items.map(
                                    (item, index) => {
                                        return (
                                            <Box
                                                key={index}
                                            >
                                                {
                                                    item.blank?
                                                        <ListItem>
                                                            <ListItemButton >
                                                                <Link
                                                                    href={item.link}
                                                                    target={'_blank'}
                                                                    color="inherit"
                                                                    underline="none"
                                                                >
                                                                    {item.icon}
                                                                    <ListItemContent
                                                                        sx={{
                                                                            'pl': 1,
                                                                        }}
                                                                    >
                                                                        {item.title}
                                                                    </ListItemContent>
                                                                </Link>
                                                            </ListItemButton>
                                                        </ListItem>:
                                                        <ListItem>
                                                            <ListItemButton onClick={() => this.props.navigate(item.link)}>
                                                                {item.icon}
                                                                <ListItemContent
                                                                    sx={{
                                                                        'pl': 1,
                                                                    }}
                                                                >
                                                                    {item.title}
                                                                </ListItemContent>
                                                            </ListItemButton>
                                                        </ListItem>
                                                }
                                            </Box>
                                        )
                                    }
                                )
                            }
                        </List>
                    ))
                }
            </Stack>
        )
    }
}

export default withNavigate(CommonSidebar);
