import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import Box from '@mui/joy/Box';
import withRouter from "../../utils/withRouter";
import CommonPaginatedTable from "../Shared/CommonPaginatedTable";


class ContactList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contacts: [],
            pages: null,
        };
    }

    async componentDidMount() {
        await this.retrieveContacts();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveContacts();
        }
    }

    async retrieveContacts() {
        const url = 'contact/';
        const api = new BaseApi();
        let params = {};
        if (this.props.params.pageNumber){
            params['page'] = this.props.params.pageNumber;
        }
        let response = await api.get(url, params);
        this.setState(
            {
                contacts: response.data.results,
                pages: response.data.total_pages,
            }
        );
    }

    render() {
        if (!this.state.contacts) {
            return (<CircularProgress/>)
        }
        if (!this.state.pages) {
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1,
                    gap: 1,
                    flexWrap: 'wrap',
                    '& > *': {
                        minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                        flexGrow: 1,
                    },
                }}
            >
                <CommonPaginatedTable
                    title={'Контакты'}
                    url={'contact'}
                    detail_url={'contact/detail/'}
                    data={this.state.contacts}
                    pages={this.state.pages}
                    pageNumber={this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1}
                    fields={[
                        {
                            title: 'Фамилия',
                            name: 'last_name',
                            linkPattern: '/contact/detail/:linkParam',
                            linkParam: 'id',
                            visible: true,
                        },
                        {
                            title: 'Имя',
                            name: 'first_name',
                            visible: true,
                        },
                        {
                            title: 'Отчество',
                            name: 'patronymic',
                            visible: true,
                        },
                        {
                            title: 'Компания',
                            name: 'account.nameshort',
                            visible: true,
                        },
                    ]
                    }
                />
            </Box>
        )
    }
}

export default withRouter(ContactList)