import React from "react";
import {Badge, CircularProgress} from "@mui/joy";
import BaseApi from "../../utils/BaseApi";

import NotificationsIcon from '@mui/icons-material/Notifications';
import withNavigate from "../../utils/withNavigate";
import Box from "@mui/joy/Box";


class NotificationButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: null,
            count: 0
        };
    }

    async componentDidMount() {
        await this.retrieveNotifications();
        setInterval(this.retrieveNotifications.bind(this), 20000);
    }

    async retrieveNotifications() {
        const url = 'notification/';
        const api = new BaseApi();
        const params = {
            'is_read': 0,
        }
        let response = await api.get(url, params);
        this.setState(
            {
                notifications: response.data.results,
                count: response.data.count,
            }
        );
    }

    render() {
        if (!this.state.notifications) {
            return (<CircularProgress />)
        }
        return(
            <Box onClick={() => this.props.navigate('/notification')} sx={{'cursor': 'pointer'}}>
                <Badge badgeContent={this.state.count} color="danger">
                    <NotificationsIcon />
                </Badge>
            </Box>
        )
    }
}

export default withNavigate(NotificationButton);
