import React from 'react';
import {CircularProgress, Stack} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";
import Box from "@mui/joy/Box";

// icons
import NoteIcon from '@mui/icons-material/Note';
import CommonEditModal from "../Shared/CommonEditModal";


class NoteCard extends React.Component {
    render() {
        if (!this.props.note) {
            return (<CircularProgress/>)
        }
        return (
            <Card
                orientation="horizontal"
                variant="outlined"
                sx={
                    {
                        mr: 1,
                        ml: 1,
                        mb: 1,
                        p: 1
                    }
                }
            >
                <CardContent
                    sx={
                        {
                            m: 0,
                        }
                    }
                >
                    <Stack
                        direction={'row'}
                        sx={{
                            alignItems: 'center',
                        }}
                    >
                        <NoteIcon
                            color={this.props.note.color}
                            sx={{
                                mr: 1,
                            }}
                        />
                        <Typography
                            level={'title-md'}
                            sx={{
                                display: 'inline',
                            }}
                        >
                            {this.props.note.text}
                        </Typography>
                        <CommonEditModal
                                fields={
                                    [
                                        'text',
                                        'color',
                                    ]
                                }
                                baseUrl={'note'}
                                obj={this.props.note}
                                callBack={this.props.callBack}
                            />
                    </Stack>
                </CardContent>
            </Card>
        )
    }
}

export default NoteCard;
