import React from "react";
import BaseApi from "../../utils/BaseApi";
import {CircularProgress, Modal, ModalClose, ModalDialog} from "@mui/joy";
import IconButton from "@mui/joy/IconButton";
import Box from "@mui/joy/Box";

// icons
import LinkIcon from '@mui/icons-material/Link';

class DeliveryControlledEntityModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            controlledEntities: null,
            modalOpen: false,
        };
    }

    async componentDidMount(){
        await this.retrieveControlledEntities();
    }

    async retrieveControlledEntities(){
        this.setState(
            {
                controlledEntities: null,
            }
        );
        const url = `delivery_controlled_entity/`;
        const api = new BaseApi();
        const params = {
            delivery: this.props.delivery.id
        };
        let response = await api.get(
            url,
            params
        );
        await this.setState(
            {
                controlledEntities: response.data.results,
            }
        );
    }

    setModalOpenTrue(){
        this.setState(
            {
                modalOpen: true,
            }
        );
    }

    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        )
    }

    render() {
        if (!this.state.controlledEntities) {
            return (
                <CircularProgress/>
            )
        }
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <IconButton
                    size={'sm'}
                    color="neutral"
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <LinkIcon />
                </IconButton>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                width: 'calc(80vw)',
                                height: 'calc(80vh)',
                                pr: 2
                            }}
                        >

                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default DeliveryControlledEntityModal