import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";


// components
import NoteCreateModal from "./NoteCreateModal";
import NoteCard from "./NoteCard";


class NoteWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: null,
        };
    }

    async componentDidMount() {
        await this.retrieveNotes();
    }

    async retrieveNotes() {
        const url = 'note/';
        const params = {
            deleted: false
        };
        const api = new BaseApi();
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                notes: response.data.results
            }
        );
    }

    render() {
        if (!this.state.notes) {
            return (<CircularProgress/>)
        }
        return (
            <Sheet
                style={
                    {
                        'maxHeight': 350,
                        'minHeight': 350,
                        'overflowY': 'scroll',
                    }
                }
            >
                {
                    this.state.notes.map(
                        (note) => (
                            <NoteCard
                                note={note}
                                key={'note-card-'+note.id}
                                callBack={this.retrieveNotes.bind(this)}
                            />
                        )
                    )
                }
                <NoteCreateModal
                    callBack={this.retrieveNotes.bind(this)}
                />
            </Sheet>
        )
    }
}

export default NoteWidget;
