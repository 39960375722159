import React from "react";
import BaseApi from "../../utils/BaseApi";
import {Modal, ModalClose, ModalDialog} from "@mui/joy";
import CommonModalInner from "../Shared/CommonModalInner";
import IconButton from "@mui/joy/IconButton";

// Tabs
import TaskDetailHeader from "./TaskDetailHeader";
import TaskTab from "./TaskTab";
import AttachmentsTab from "../Attachment/AttachmentsTab";

// icons
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import Box from "@mui/joy/Box";
import TaskDailyDurationsTab from "./TaskDailyDurationsTab";


class TaskDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            task: null,
            modalOpen: false,
        };
    }

    async retrieveTask() {
        this.setState({
            task: null
        });
        const url = `task/${this.props.taskId}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        await this.setState(
            {
                task: response.data
            }
        );
    }

    async setModalOpenTrue(){
        await this.setState({
            modalOpen: true,
        })
        await this.retrieveTask();
    }

    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        )
        if (this.props.callBack) {
            await this.props.callBack();
        }
    }

    render() {
        if (!this.state.task) {
            return (
                <Box
                    sx={{
                        display: 'inline',
                    }}
                >
                    <IconButton
                        size={'sm'}
                        color="neutral"
                        onClick={this.setModalOpenTrue.bind(this)}
                    >
                        <WebAssetIcon />
                    </IconButton>
                </Box>
            )
        }
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <IconButton
                    size={'sm'}
                    color="neutral"
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <WebAssetIcon />
                </IconButton>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <CommonModalInner
                            appName={'Task'}
                            appId={this.state.task.id}
                            headerComponent={<TaskDetailHeader task={this.state.task} />}
                            defaultTabValue={'info'}
                            headerOverflowTitle={this.state.task.work_status_human}
                            headerOverflowColor={this.state.task.work_status_color}
                            tabComponents={[
                                {
                                    title: 'Информация',
                                    value: 'info',
                                    icon: <AccountTreeIcon />,
                                    component: <TaskTab
                                        task={this.state.task}
                                        callBack={this.retrieveTask.bind(this)}
                                    />,
                                },
                                {
                                    title: 'Вложения',
                                    value: 'attachment',
                                    icon: <AttachFileIcon />,
                                    component: <AttachmentsTab
                                        appId={this.state.task.id}
                                        appName={'Task'}
                                    />,
                                },
                                {
                                    title: 'Трудозатраты',
                                    value: 'dailyDurations',
                                    icon: <AccessAlarmIcon />,
                                    component: <TaskDailyDurationsTab
                                        taskId={this.state.task.id}
                                    />,
                                },
                            ]}
                        />
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default TaskDetail
