import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";

// fullCalendar
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import mergedTheme from "../../theme.ts";
import TaskDetail from "./TaskDetail";
import dayjs from "dayjs";


class TaskSubscriberCalendarCore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: null
        };
    }

    async componentDidMount() {
        await this.retrieveEvents();
    }

    async retrieveEvents() {
        const url = `subscriber/${this.props.subscriber.id}/calendar/`;
        const api = new BaseApi();
            let response = await api.get(
            url,
            {}
        );
        let events = []
        for (const holiday of response.data.holidays) {
            events.push(
                {
                    title: 'Выходной',
                    start: `${holiday.day}T09:00:00`,
                    end: `${holiday.day}T18:00:00`,
                    backgroundColor: mergedTheme.colorSchemes.dark.palette.teal[400],
                    borderColor: mergedTheme.colorSchemes.dark.palette.teal[400],
                }
            )
        }
        for (const vacation of response.data.vacations) {
            events.push(
                {
                    title: 'Отпуск',
                    start: `${vacation.day}T09:00:00`,
                    end: `${vacation.day}T18:00:00`,
                    backgroundColor: mergedTheme.colorSchemes.dark.palette.purple[400],
                    borderColor: mergedTheme.colorSchemes.dark.palette.purple[400],
                }
            )
        }
        for (const task of response.data.tasks) {
            let end_date = new Date(Date.parse(task.end_dt));
            end_date.setDate(end_date.getDate() + 1);
            // switch if task status - color
            events.push(
                {
                    title: task.title,
                    start: task.start_dt,
                    end: dayjs(end_date).format('YYYY-MM-DD'),
                    obj: task,
                    backgroundColor: mergedTheme.palette[task.work_status_color][400],
                    borderColor: mergedTheme.palette[task.work_status_color][400],
                }
            )
        }

        for (const load of response.data.load_by_date_with_time) {
            if (load[1][0][1]!==load[1][0][2]) {
                events.push(
                    {
                        title: `Факт: проекты ${load[1][0][0][0].toFixed(1)}, вне ${load[1][0][0][1].toFixed(1)}`,
                        start: `${load[0]}T${load[1][0][1].toString().padStart(2, '0')}:00:00`,
                        end: `${load[0]}T${load[1][0][2].toString().padStart(2, '0')}:00:00`,
                        backgroundColor: mergedTheme.palette.success[400],
                        borderColor: mergedTheme.palette.success[400],
                    }
                )
            }
            if (load[1][1][1]!==load[1][1][2]) {
                events.push(
                    {
                        title: `Процесс: проекты ${load[1][1][0][0].toFixed(1)}, вне ${load[1][1][0][1].toFixed(1)}`,
                        start: `${load[0]}T${load[1][1][1].toString().padStart(2, '0')}:00:00`,
                        end: `${load[0]}T${load[1][1][2].toString().padStart(2, '0')}:00:00`,
                        backgroundColor: mergedTheme.palette.primary[400],
                        borderColor: mergedTheme.palette.primary[400],
                    }
                )
            }
            if (load[1][2][1] !== load[1][2][2]) {
                events.push(
                    {
                        title: `План: проекты ${load[1][2][0][0].toFixed(1)}, вне ${load[1][2][0][1].toFixed(1)}`,
                        start: `${load[0]}T${load[1][2][1].toString().padStart(2, '0')}:00:00`,
                        end: `${load[0]}T${load[1][2][2].toString().padStart(2, '0')}:00:00`,
                        backgroundColor: mergedTheme.palette.primary[400],
                        borderColor: mergedTheme.palette.primary[400],
                    }
                )
            }
        }
        this.setState({
            events: events
        })
    }

    renderEventContent(eventInfo) {
        return (
            <>
                {eventInfo.event.title}
                {
                    eventInfo.event.extendedProps.obj?
                        <TaskDetail
                            taskId={eventInfo.event.extendedProps.obj.id}
                        />:
                        null
                }

            </>
        )
    }

    render() {
        if (!this.state.events) {
            return (<CircularProgress/>)
        }
        return (
            <Sheet
                variant={'soft'}
            >
                <FullCalendar
                    plugins={[timeGridPlugin, dayGridPlugin]}
                    initialView="timeGridWeek"
                    weekends={false}
                    events={this.state.events}
                    views={{
                        timeGrid: {
                            slotMinTime: '09:00:00',
                            slotMaxTime: '24:00:00',
                        }
                    }}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    eventContent={this.renderEventContent}
                    locale={'ru'}
                />
            </Sheet>
        )
    }
}

export default TaskSubscriberCalendarCore;
