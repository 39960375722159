import React from 'react';

import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import withNavigate from "../../utils/withNavigate";
import DeliveryTable from "./DeliveryTable";
import Sheet from "@mui/joy/Sheet";


class DeliveryWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveries: [],
        };
    }

    async componentDidMount() {
        await this.retrieveDeliveries();
    }

    async retrieveDeliveries() {
        const url = 'delivery_state/my/';
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                deliveries: response.data.results
            }
        );
    }

    render() {
        if (!this.state.deliveries) {
            return (<CircularProgress/>)
        }
        return (
            <Sheet
                style={
                    {
                        'maxHeight': 350,
                        'minHeight': 350,
                        'overflowY': 'scroll',
                    }
                }
            >
                <DeliveryTable
                    deliveries={this.state.deliveries}
                    showAccount={true}
                    showOpportunity={true}
                    callBack={this.retrieveDeliveries.bind(this)}
                />
            </Sheet>
        )
    }
}

export default withNavigate(DeliveryWidget);
