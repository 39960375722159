import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import Box from '@mui/joy/Box';
import RouteTable from "./RouteTable";


class RouteSubordinateList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            routes: [],
        };
    }

    async componentDidMount() {
        await this.retrieveRoutes();
    }

    async retrieveRoutes() {
        const url = 'route_state/';
        const params = {
            operator: this.props.subscriber.id,
            passed_by__isnull: true,
            route__isnull: false,
        };
        const api = new BaseApi();
        let response = await api.get(url, params);
        this.setState(
            {
                routes: response.data.results
            }
        );
    }

    render() {
        if (!this.state.routes) {
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1,
                    gap: 1,
                    flexWrap: 'wrap',
                    '& > *': {
                        minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                        flexGrow: 1,
                    },
                }}
            >
                <RouteTable routes={this.state.routes} />
            </Box>
        )
    }
}

export default RouteSubordinateList;
