import React from "react";
import BaseApi from "../../../utils/BaseApi";
import {CircularProgress, Modal, ModalClose, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";

// icons
import CommonForm from "../../Shared/CommonForm";
import Typography from "@mui/joy/Typography";
import EditIcon from '@mui/icons-material/Edit';
import Link from "@mui/joy/Link";


class RouteActionCreateUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            formFields: null,
            success: false,
        };
    }

    async componentDidMount(){
        await this.retrieveFields();
    }

    async retrieveFields(){
        const url = `route_stage_action_create/update_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                formFields: response.data
            }
        );
    }

    async setModalOpenTrue(){
        await this.setState({
            modalOpen: true,
        })
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        if ('next_stages' in formDataObj) {
            if (formDataObj.next_stages === '') {
                formDataObj.next_stages = [];
            }
            else{
                formDataObj.next_stages = formDataObj.next_stages.split(',');
            }
        }
        const url = `route_stage_action_create/${this.props.obj.id}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            formDataObj
        );
        if (response.status === 200) {
            await this.setModalOpenFalse();
        }
    }

    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
        await this.props.callBack();
    }

    render() {
        if (!this.state.formFields){
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <Link
                    level="body-xs"
                    color={'primary'}
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <EditIcon/>
                </Link>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                height: 'calc(80vh)',
                                pr: 2
                            }}
                        >
                            <Typography
                                level={'h2'}
                                sx={{mb: 2}}
                            >
                                Редактирование порождения
                            </Typography>
                            <CommonForm
                                fields={this.state.formFields}
                                processForm={this.processForm.bind(this)}
                                object={this.props.obj}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default RouteActionCreateUpdate
