import React from "react";

import Button from "@mui/joy/Button";
import {Modal, ModalClose, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";

// components
import CommonForm from "./CommonForm";

// icons
import EditIcon from '@mui/icons-material/Edit';
import BaseApi from "../../utils/BaseApi";


class CommonEditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            formFields: null,
        };
    }

    async retrieveFields() {
        const url = `${this.props.baseUrl}/update_form/`;
        const api = new BaseApi();
        const params = {
            fields: JSON.stringify(
                this.props.m2mFields?
                    this.props.fields.concat(this.props.m2mFields):
                    this.props.fields
            )
        }
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                formFields: response.data
            }
        );
    }

    async setModalOpenTrue() {
        await this.retrieveFields();
        await this.setState({
            modalOpen: true,
        })
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        if (this.props.m2mFields){
            for (const key in formDataObj) {
                if (this.props.m2mFields.indexOf(key) > -1){
                    if (formDataObj[key] !== '') {
                        formDataObj[key] = formDataObj[key].split(',');
                    }
                }
            }
        }
        const url = `${this.props.baseUrl}/${this.props.obj.id}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            formDataObj
        );
        if (response.status === 200 ){
            if(typeof this.props.callBack == 'function') {
                await this.props.callBack();
            }
            this.setState({
                modalOpen: false,
            })
        }
    }

    render() {
        return(
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <Button
                    size={'sm'}
                    variant={'plain'}
                    color="neutral"
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <EditIcon
                        sx={{
                            fontSize: '12px',
                        }}
                    />
                </Button>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vh)',
                                pr: 2
                            }}
                        >
                            <CommonForm
                                fields={this.state.formFields}
                                object={this.props.obj}
                                processForm={this.processForm.bind(this)}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default CommonEditModal