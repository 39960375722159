import React from "react";
import BaseApi from "../../utils/BaseApi";
import {CircularProgress, Modal, ModalClose, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";

// icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from "@mui/joy/Button";
import CommonForm from "../Shared/CommonForm";
import Typography from "@mui/joy/Typography";


class ContactTeamCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            formFields: null,
            success: false,
        };
    }

    async componentDidMount(){
        await this.retrieveFields();
        await this.retrieveContacts();
    }

    async retrieveContacts(formFields){
        const url = `contact/`;
        const api = new BaseApi();
        const params = {
            account: this.props.opportunity.account.id
        }
        let response = await api.get(
            url,
            params
        );
        for (const field of formFields){
            if (field.name === 'contact') {
                field.options = response.data.results.map(each => {
                    return (
                        {
                            'value': each.id,
                            'label': `${each.first_name} ${each.last_name}`,
                        }
                    )
                })
            }
        }
        this.setState(
            {
                formFields: formFields
            }
        );
    }

    async retrieveFields(){
        const url = `opp_contact_team/create_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        await this.retrieveContacts(response.data);
    }


    async setModalOpenTrue(){
        await this.setState({
            modalOpen: true,
        })
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        formDataObj.opportunity = this.props.opportunity.id
        const url = `opp_contact_team/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 201) {
            this.setState(
                {
                    success: true
                }
            );
        }
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
        this.props.callBack();
    }

    render() {
        if (!this.state.formFields){
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <Button
                    size={'sm'}
                    color="primary"
                    sx={{mt: 2}}
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <AddCircleIcon sx={{mr: 1}}/> Добавить
                </Button>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                height: 'calc(80vh)',
                                pr: 2
                            }}
                        >
                            {
                                this.state.success?
                                    <>
                                        <Typography>
                                            Участник команды заказчика добавлен успешно!
                                        </Typography>
                                        <Button
                                            color="success"
                                            sx={{mt: 2}}
                                            onClick={this.setModalOpenFalse.bind(this)}
                                        >
                                            Закрыть вкладку
                                        </Button>
                                    </>
                                :
                                    <>
                                        <Typography
                                            level={'h2'}
                                            sx={{mb: 2}}
                                        >
                                            Добавление участника команды заказчика
                                        </Typography>
                                        <CommonForm
                                            fields={this.state.formFields}
                                            processForm={this.processForm.bind(this)}
                                        />
                                    </>
                            }
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default ContactTeamCreate
