import React from 'react';
import Typography from "@mui/joy/Typography";
import KpiSubscriberList from "./KpiSubscriberList";
import UserSubscriberContext from "../../context/UserSubscriberContext";


class KpiMyList extends React.Component {
    static contextType = UserSubscriberContext

    render() {
        return (
            <>
                <Typography
                    level={'h1'}
                >
                    Мои КПЭ
                </Typography>
                <KpiSubscriberList
                    subscriber={this.context.subscriberObject}
                />
            </>
        )
    }
}

export default KpiMyList