import React from 'react';
import Typography from "@mui/joy/Typography";
import {Stack} from "@mui/joy";
import Link from "@mui/joy/Link";

// components
import withNavigate from "../../utils/withNavigate";
import CommonEditModal from "../Shared/CommonEditModal";
import retrievePermission from "../../utils/retrievePermission";
import UserSubscriberContext from "../../context/UserSubscriberContext";


class ContactDetailHeader extends React.Component{
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            updatePermission: 0,
        };
    }

    async componentDidMount(){
        await this.retrieveUpdatePermission();
    }

    async retrieveUpdatePermission() {
        let p = await retrievePermission(
            'ContactViewSet',
            'partial_update',
            this.context.subscriberObject
        );
        await this.setState(
            {
                updatePermission: p
            }
        );
    }

    render() {
        return(
            <Stack>
                <Typography
                    level={'h4'}
                >
                    {this.props.contact.first_name} {this.props.contact.patronymic} {this.props.contact.last_name}
                    {this.state.updatePermission?
                        <CommonEditModal
                            fields={
                                [
                                    'last_name',
                                    'patronymic',
                                    'last_name'
                                ]
                            }
                            baseUrl={'contact'}
                            obj={this.props.contact}
                            callBack={this.props.callBack}
                        />:
                        null
                    }

                </Typography>
                <Typography
                    level={'body-md'}
                >
                    {this.props.contact.role}
                    {this.state.updatePermission?
                        <CommonEditModal
                            fields={
                                [
                                    'role',
                                ]
                            }
                            baseUrl={'contact'}
                            obj={this.props.contact}
                            callBack={this.props.callBack}
                        />:
                        null
                    }
                     в&nbsp;
                    <Link onClick={() => this.props.navigate(`/account/detail/${this.props.contact.account.id}`)}>
                        {this.props.contact.account.nameshort}
                    </Link>
                </Typography>
            </Stack>
        )
    }
}

export default withNavigate(ContactDetailHeader)