import React from "react";
import * as echarts from "echarts";

import UserSubscriberContext from "../../context/UserSubscriberContext";
import BaseApi from "../../utils/BaseApi";
import IconButton from "@mui/joy/IconButton";
import {Modal, ModalClose, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";

// icons
import AltRouteIcon from '@mui/icons-material/AltRoute';
import {getElementByIdAsync} from "../../utils/getElementByIdAsync";


class DeliveryGraph extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            modalOpen: false,
        };
    }

    async componentDidUpdate(prevProps,  prevState, snapShot){
        let chartDom = await getElementByIdAsync('echart');
        this.myChart = await echarts.init(chartDom);
        let option = {
            title: {
                text: this.state.data.title,
                // subtext: 'Default layout',
                top: 'bottom',
                left: 'right'
            },
            legend: this.state.data.categories,
            animationDuration: 100,
            animationEasingUpdate: 'quinticInOut',
            series: [
                {
                    type: 'graph',
                    layout: 'force',
                    symbolSize: 30,
                    roam: true,
                    draggable: true,
                    data: this.state.data.nodes,
                    categories: this.state.data.categories,
                    force: {
                        repulsion: 2000,
                        gravity: 0.3,
                        friction: 0.9,
                    },
                    lineStyle: {
                        color: 'source',
                        curveness: 0.3
                    },
                    edgeSymbol: ['circle', 'arrow'],
                    edgeSymbolSize: [4, 10],
                    label: {
                        show: true,
                        position: 'right',
                        color: 'white'
                        // formatter: '{b}'
                    },
                    edges: this.state.data.links,
                    emphasis: {
                        focus: 'adjacency',
                        blurScope: 'coordinateSystem',
                    }
                }
            ]
        }
        this.myChart.setOption(option);
    }

    async setModalOpenTrue(){
        const url = `delivery/${this.props.deliveryId}/xtype_graph/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        await this.setState(
            {
                modalOpen: true,
                data: response.data
            }
        );
    }



    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        )
    }

    render() {
        return(
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <IconButton
                    size={'sm'}
                    color="neutral"
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <AltRouteIcon />
                </IconButton>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                width: 'calc(80vw)',
                                height: 'calc(80vh)',
                                pr: 2
                            }}
                        >
                            <Box
                                id={'echart'}
                                sx={{
                                    height: '100%',
                                }}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default DeliveryGraph