import React from "react";
import IconButton from "@mui/joy/IconButton";
import {Modal, ModalClose, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";

//icons
import SearchIcon from '@mui/icons-material/Search';
import SearchResults from "./SearchResults";
import BaseApi from "../../utils/BaseApi";
import CommonForm from "../Shared/CommonForm";


class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            results: [],
            query: ''
        };
    }

    async setModalOpenTrue(){
        await this.setState({
            modalOpen: true,
        })
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
    }

    async performSearch(query) {
        const url = 'search/';
        const api = new BaseApi();
        const params = {
            query: query
        }
        let response = await api.get(url, params);
        if (response.status === 200){
            return response.data
        }
        else{
            return []
        }
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        let results = await this.performSearch(formDataObj.query);
        await this.setState({
            results: results,
            query: formDataObj.query,
        });
    }

    render() {
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <IconButton
                    size={'sm'}
                    color="primary"
                    // sx={{mt: 2, mr: 2}}
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <SearchIcon/>
                </IconButton>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                height: 'calc(80vh)',
                                pr: 2
                            }}
                        >
                            <Typography
                                level={'h2'}
                                sx={{mb: 2}}
                            >
                                Поиск
                            </Typography>
                            <CommonForm
                                fields={
                                    [
                                        {
                                            'name': 'query',
                                            'label': 'Запрос',
                                            'xs': 12,
                                            'sm': null,
                                            'md': null,
                                            'lg': null,
                                            'xl': null,
                                            'required': true,
                                            'default': this.state.query,
                                            'type': 'input',
                                        },
                                        {
                                            'type': 'submit',
                                            'label': 'Искать',
                                        }
                                    ]
                                }
                                processForm={this.processForm.bind(this)}
                            />
                            <SearchResults
                                results={this.state.results}
                                callBack={this.setModalOpenFalse.bind(this)}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default Search