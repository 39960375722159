import React from "react";
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import OpportunityCard from "./OpportunityCard";


class OpportunityTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opportunities: null,
        };
    }

    async componentDidMount() {
        await this.retrieveOpportunities();
    }

    async retrieveOpportunities() {
        const url = `opportunity/`;
        const api = new BaseApi();
        let params = {}
        if (this.props.accountId){
            params.account = this.props.accountId
        }
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                opportunities: response.data.results
            }
        );
    }

    render() {
        if (!this.state.opportunities) {
            return (<CircularProgress/>)
        }
        return (
            <>
                {this.state.opportunities.map((each) => (
                    <OpportunityCard
                        opportunityID={each.id}
                    />
                ))}
            </>
        )
    }
}

export default OpportunityTab