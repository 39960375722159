import React from 'react';
import {CircularProgress, Stack} from "@mui/joy";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";

// components
import BaseApi from "../../utils/BaseApi";
import CommunicationCard from "./CommunicationCard";
import Typography from "@mui/joy/Typography";
import CommunicationCreate from "./CommunicationCreate";


class CommunicationsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            communications: null,
        };
    }

    async componentDidMount() {
        await this.retrieveCommunications();
    }

    async retrieveCommunications() {
        const url = `communication/`;
        const params = {
            related_app: this.props.related_app,
            related_app_id: this.props.related_app_id,
        };
        const api = new BaseApi();
        let response = await api.get(
            url,
            params
        );
        await this.setState(
            {
                communications: response.data.results
            }
        );
    }

    render() {
        if (!this.state.communications){
            return (<CircularProgress/>)
        }
        return(
            <Card>
                <CardContent>
                    <Stack
                        spacing={2}
                    >
                        <Typography
                            level={'h4'}
                        >
                            Способы связи
                        </Typography>
                        {
                            this.state.communications.length === 0 ?
                                <Typography
                                    level={'body-md'}
                                >
                                    Список пуст
                                </Typography> :
                                null
                        }
                        {
                            this.state.communications.map(each => {
                                return(
                                    <CommunicationCard
                                        communication={each}
                                        callBack={this.retrieveCommunications.bind(this)}
                                    />
                                )
                            })
                        }
                        <CommunicationCreate
                            related_app={this.props.related_app}
                            related_app_id={this.props.related_app_id}
                            callBack={this.retrieveCommunications.bind(this)}
                        />
                    </Stack>
                </CardContent>
            </Card>
        )
    }
}

export default CommunicationsCard