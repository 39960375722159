import React from "react";

// components
import BaseApi from "../../utils/BaseApi";
import CommonPaginatedTable from "../Shared/CommonPaginatedTable";
import {CircularProgress} from "@mui/joy";


class TaskDailyDurationsTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            durations: null,
            durationsPages: null,
            durationsPage: 1,
        };
    }

    async componentDidMount() {
        await this.retrieveDurations();
    }

    async retrieveDurations(page=1) {
        await this.setState(
            {
                durations: null
            }
        );
        const url = `task_daily_duration/`;
        const params = {
            page: page,
            task: this.props.taskId
        };
        const api = new BaseApi();
        let response = await api.get(url, params);
        await this.setState(
            {
                durations: response.data.results,
                durationsPages: response.data.total_pages,
                durationsPage: page,
            }
        );
    }

    render() {
        if (!this.state.durations) {
            return (<CircularProgress/>)
        }
        return(
            <CommonPaginatedTable
                    data={this.state.durations}
                    pages={this.state.durationsPages}
                    pageNumber={this.state.durationsPage}
                    pagesCallback={this.retrieveDurations.bind(this)}
                    fields={[
                         {
                            title: 'Дата',
                            name: 'date',
                            visible: true,
                        },
                        {
                            title: 'Плановая трудоемкость',
                            name: 'duration_plan',
                            visible: true,
                        },
                        {
                            title: 'Фактическая трудоемкость',
                            name: 'duration_fact',
                            visible: true,
                        },
                    ]
                    }
                />
        )
    }
}

export default TaskDailyDurationsTab