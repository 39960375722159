import React from "react";

import Sheet from "@mui/joy/Sheet";
import {CircularProgress} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";

// components
import NotificationLink from "./NotificationLink";
import withRouter from "../../utils/withRouter";
import BaseApi from "../../utils/BaseApi";


class NotificationDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notification: null,
            links: null
        };
    }

    async componentDidMount() {
        await this.retrieveNotification(this.props.params.notificationId);
    }

    async retrieveNotification(id) {
        const api = new BaseApi();
        let url = `notification/${id}/`;
        const data = {
            is_read: true,
        };
        let notificationResponse = await api.patch(
            url,
            data,
        );
        url = 'notification_link/';
        const params = {
            notification: id,
        };
        let linksResponse = await api.get(
            url,
            params
        )
        this.setState(
            {
                notification: notificationResponse.data,
                links: linksResponse.data.results,
            }
        );
    }

    render() {
        if (!this.state.notification) {
            return (<CircularProgress/>)
        }
        if (this.state.links === null) {
            return (<CircularProgress/>)
        }
        return (
            <Sheet
                variant="outlined"
                color="neutral"
                sx={
                    {
                        p: 4,
                        height: '100%',
                        alignContent: 'space-between',
                        // display: 'flex',
                        flexWrap: 'wrap'
                    }
                }
            >
                <Typography
                    level={'h2'}
                >
                    {this.state.notification.title}
                </Typography>
                <Box>
                    <div dangerouslySetInnerHTML={{__html: this.state.notification.desc}} />
                </Box>
                <Typography
                    level={'h3'}
                    sx={{
                        mb: 2,
                    }}
                >
                    Ссылки
                </Typography>
                {this.state.links.map(
                    (link) => (
                        <Box
                            key={'link-'+link.id}
                        >
                            <NotificationLink
                                link={link}
                            />
                        </Box>
                    )
                )}
            </Sheet>
        )
    }
}

export default withRouter(NotificationDetail);
