import React from 'react';
import { BaseEdge, BezierEdge } from 'reactflow';
import FloatingEdge from "./FloatingEdge";

export default function SelfConnectingEdge(props) {
  // we are using the default bezier edge when source and target ids are different
  if (props.source !== props.target) {
    return <FloatingEdge {...props} style={{ strokeWidth: 4 }} />;
  }

  const { sourceX, sourceY, targetX, targetY, id, markerEnd } = props;
  const radiusY = 200;
  const radiusX = 200;
  const edgePath = `M ${sourceX} ${sourceY} A ${radiusX} ${radiusY} 0 1 1 ${
    targetX + 2
  } ${targetY}`;

  return <BaseEdge path={edgePath} markerEnd={markerEnd} style={{ strokeWidth: 4 }}/>;
}
