import React from "react";
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import CommonDetail from "../Shared/CommonDetail";
import withRouter from "../../utils/withRouter";

// components
import LeadTab from "./LeadTab";
import LeadDetailHeader from "./LeadDetailHeader";
import RouteTab from "../Route/RouteTab";
import AttachmentsTab from "../Attachment/AttachmentsTab";

// icons
import InfoIcon from '@mui/icons-material/Info';
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AttachFileIcon from "@mui/icons-material/AttachFile";


class LeadDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lead: null,
        };
    }

    async componentDidMount() {
        await this.retrieveLead();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveLead();
        }
    }

    async retrieveLead() {
        await this.setState(
            {
                account: null
            }
        );
        const url = `lead/${this.props.params.leadId}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        await this.setState(
            {
                lead: response.data
            }
        );
    }

    render() {
        if (!this.state.lead) {
            return (<CircularProgress/>)
        }
        return (
            <CommonDetail
                appName={'Lead'}
                appId={this.state.lead.id}
                headerComponent={
                    <LeadDetailHeader
                        lead={this.state.lead}
                    />
                }
                defaultTabValue={'info'}
                headerOverflowTitle={'Лид'}
                headerOverflowColor={'primary'}
                tabComponents={[
                    {
                        title: 'Информация',
                        value: 'info',
                        icon: <InfoIcon />,
                        component: <LeadTab
                            lead={this.state.lead}
                            callBack={this.retrieveLead.bind(this)}
                        />,
                    },
                    {
                        title: 'Вложения',
                        value: 'attachment',
                        icon: <AttachFileIcon />,
                        component: <AttachmentsTab
                            appId={this.state.lead.id}
                            appName={'Lead'}
                        />
                    },
                ]}
            />
        )
    }
}

export default withRouter(LeadDetail);
