import React from 'react';
import Typography from "@mui/joy/Typography";

// components
import UserSubscriberContext from "../../context/UserSubscriberContext";
import TaskSubscriberCalendarCore from "./TaskSubscriberCalendarCore";


class TaskMyCalendar extends React.Component {
    static contextType = UserSubscriberContext

    render() {
        return (
            <>
                <Typography
                    level={'h1'}
                >
                    Мой календарь загрузки
                </Typography>
                <TaskSubscriberCalendarCore
                    subscriber={this.context.subscriberObject}
                />
            </>
        )
    }
}

export default TaskMyCalendar