import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

export const openSidebar = () => {
    if (typeof document !== 'undefined') {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }
};

export const closeSidebar = () => {
    if (typeof document !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn');
        document.body.style.removeProperty('overflow');
    }
};

export const toggleSidebar = () => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const slideIn = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--SideNavigation-slideIn');
        if (slideIn) {
            closeSidebar();
        } else {
            openSidebar();
        }
    }
};

export function getNestedField(obj, name, boolean_negative=null){
    let fields = name.split('.');
    let value = obj;
    for (let field of fields) {
        if (!value) {
            return 'N/A'
        }
        value = value[field];
    }
    if (typeof value === 'boolean'){
        if (value) {
            return <CheckCircleIcon color={"success"}/>
        }
        if (boolean_negative === 'wait'){
            return <HourglassEmptyIcon color={'warning'}/>
        }
        return <HighlightOffIcon color={"error"}/>
    }
    return value
}