import React from 'react';
import {Switch} from "@mui/joy";

// components
import BaseApi from "../../utils/BaseApi";


class EstimationPhaseActive extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            phase: this.props.phase,
        };
    }

    async setOpposite() {
        const url = `estimation_phase/${this.props.phase.id}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            {is_active: !this.state.phase.is_active}
        );
        if (response.status === 200){
            await this.setState({
                phase: response.data
            })
        }
    }

    render() {
        return(
            <Switch
                color={this.state.phase.is_active?'success':'warning'}
                onChange={this.setOpposite.bind(this)}
                checked={this.state.phase.is_active}
            />
        )
    }

}

export default EstimationPhaseActive