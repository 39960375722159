import React from 'react';
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from '@mui/joy/CardOverflow';
import {CircularProgress} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";


class KpiCard extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.kpi.rate >= 1.25) {
            this.color = 'danger';
        }
        else if (this.props.kpi.rate <= 0.85) {
            this.color = 'success';
        }
        else {
            this.color = 'warning';
        }
        if (this.props.kpi.rate < 1) {
            this.rate = this.props.kpi.rate * 100;
        }
        else {
            this.rate = 100;
        }
    }


    render() {
        return (
            <Card
                variant="solid"
                color={this.color}
                invertedColors
                sx={{
                    ml: 3,
                    mr: 3
                }}
            >
                <CardContent
                    orientation="horizontal"
                >
                    <CardOverflow
                        sx={{
                            px: 0.2,
                            mr: 1,
                            writingMode: 'sideways-lr',
                            textAlign: 'center',
                            fontSize: 'xs',
                            fontWeight: 'xl',
                            letterSpacing: '1px',
                            textTransform: 'uppercase',
                        }}
                    >
                        Период {this.props.kpi.period}
                    </CardOverflow>
                    <CircularProgress
                        // size="lg"
                        determinate
                        value={this.rate}
                        sx={{ '--CircularProgress-size': '80px' }}
                    >
                        {(this.props.kpi.rate * 100).toFixed(1)} %
                    </CircularProgress>
                    <Box>
                        <Typography level="h2">{this.props.kpi.value.toFixed(2)}</Typography>
                        <Typography level="body-md">
                            из {this.props.kpi.pass_value.toFixed(2)}
                        </Typography>
                        <Typography level="body-md">
                            разница: {this.props.kpi.diff.toFixed(2)}
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        )
    }
}

export default KpiCard