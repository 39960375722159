import React from 'react';
import Typography from "@mui/joy/Typography";
import {CircularProgress} from "@mui/joy";

// components
import TaskSubscriberCalendarCore from "./TaskSubscriberCalendarCore";
import BaseApi from "../../utils/BaseApi";
import withRouter from "../../utils/withRouter";



class TaskSubscriberCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriber: null,
        };
    }

    async componentDidMount() {
        await this.retrieveSubscriber();
    }

    async retrieveSubscriber() {
        const url = `subscriber/${this.props.params.subscriberId}/`;
        const api = new BaseApi();
        let response = await api.get(
            url,{}
        );
        this.setState({
            subscriber: response.data
        })
    }

    render() {
        if (!this.state.subscriber) {
            return (<CircularProgress/>)
        }
        return (
            <>
                <Typography
                    level={'h1'}
                >
                    Календарь загрузки - {this.state.subscriber.user.last_name} {this.state.subscriber.user.first_name}
                </Typography>
                <TaskSubscriberCalendarCore
                    subscriber={this.state.subscriber}
                />
            </>
        )
    }
}

export default withRouter(TaskSubscriberCalendar)