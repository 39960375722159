export const getElementByIdAsync = id => new Promise(resolve => {
    const getElement = () => {
        const element = document.getElementById(id);
        if (element) {
            resolve(element);
        } else {
            requestAnimationFrame(getElement);
        }
    };
    getElement();
});