import React from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {CircularProgress, Stack, Step, StepButton, StepIndicator, Stepper} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import {Check} from "@mui/icons-material";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Divider from "@mui/joy/Divider";

// components
import withNavigate from "../../utils/withNavigate";
import BaseApi from "../../utils/BaseApi";
import CommonForm from "../Shared/CommonForm";

// icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';


class TaskCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: [
                {
                    order: 0,
                    title: 'Компания',
                },
                {
                    order: 1,
                    title: 'Сделка',
                },
                {
                    order: 2,
                    title: 'Этап трудоемкости',
                },
                {
                    order: 3,
                    title: 'Детали',
                },
            ],
            lockedSteps: this.props.lockedSteps,
            activeStep: this.props.activeStep,
            accounts: null,
            account: this.props.account,
            accountObj: null,
            opportunities: null,
            opportunity: this.props.opportunity,
            opportunityObj: null,
            phase: this.props.phase,
            phaseObj: null,
            phases: [],
            executors: null,
        };
    }

    async componentDidMount() {
        await this.retrieveAccounts();
        await this.retrieveExecutors();
    }

    async retrieveAccounts() {
        const url = 'account/';
        const api = new BaseApi();
        let params = {
            page_size: 10000
        }
        let response = await api.get(url, params);
        this.setState(
            {
                accounts: response.data.results,
            }
        );
    }

    async retrieveAccount(id) {
        const url = `account/${id}`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        return response.data
    }

    async retrieveOpportunities(account) {
        const url = 'opportunity/'
        const params = {
            account: account
        }
        const api = new BaseApi();
        let response = await api.get(url, params);
        this.setState({
            opportunities: response.data.results,
        });
    }

    async retrieveOpportunity(id) {
        const url = `opportunity/${id}`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        return response.data
    }

    async retrievePhases(opportunity) {
        if (opportunity) {
            const url = 'estimation_phase/'
            const params = {
                opportunity: opportunity
            }
            const api = new BaseApi();
            let response = await api.get(url, params);
            this.setState({
                phases: response.data.results,
            });
        }
    }

    async retrievePhase(id) {
        const url = `estimation_phase/${id}`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        return response.data
    }

    async retrieveExecutors() {
        const url = 'subscriber/descendants/'
        const params = {
            include_self: 1
        };
        const api = new BaseApi();
        let response = await api.get(
            url,
            params,
        );
        await this.setState(
            {
                executors: response.data.results
            }
        );
    }

    async processStep0(form) {
        let account = form.state.formData.get('account');
        let accountObj = await this.retrieveAccount(account);
        await this.retrieveOpportunities(account);
        await this.setState(
            {
                activeStep: 1,
                account: account,
                accountObj: accountObj,
            }
        );
    }

    async processStep1(form, event) {
        let opportunity = form.state.formData.get('opportunity');
        if (opportunity !== 'NaN') {
            await this.retrievePhases(opportunity);
            let opportunityObj = await this.retrieveOpportunity(opportunity);
            await this.setState(
                {
                    activeStep: 2,
                    opportunity: opportunity,
                    opportunityObj: opportunityObj,
                }
            );
        }
        else {
            this.setState(
                {
                    activeStep: 3,
                }
            );
        }
    }

    async processStep2(form, event) {
        let phase = form.state.formData.get('phase');
        if (phase !== 'NaN'){
            let phaseObj = await this.retrievePhase(phase);
            await this.setState(
                {
                    activeStep: 3,
                    phase: phase,
                    phaseObj: phaseObj,
                }
            );
        }
        else {
            this.setState(
                {
                    activeStep: 3,
                }
            );
        }
    }

    async processStep3(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        formDataObj.account = this.state.account;
        formDataObj.opportunity = this.state.opportunity;
        formDataObj.phase = this.state.phase;
        let executor_m2m = formDataObj.executor_m2m.split(',');
        const url = `task/`;
        const api = new BaseApi();
        for (const executor of executor_m2m) {
            formDataObj.executor = executor;
            await api.post(
                url,
                formDataObj
            );
        }
        await this.props.callBack();
    }

    setStep(order) {
        this.setState(
            {activeStep: order}
        )
    }

    render() {
        let stepper = (
            <>
                <Typography
                    level='h3'
                    startDecorator={<AssignmentIcon />}
                    sx={{
                        'mb': 3,
                    }}
                >
                    Создание задачи
                </Typography>
                <Stepper
                    sx={{
                        width: '100%',
                        mb: 2,
                    }}
                >
                    {this.state.steps.map((step, index) => (
                        <Step
                            key={'step'+step.order}
                            indicator={
                                <StepIndicator
                                    variant={this.state.activeStep <= step.order ? 'soft' : 'solid'}
                                    color={this.state.activeStep < step.order ? 'neutral' : 'primary'}
                                >
                                    {this.state.activeStep <= step.order ? step.order + 1 : <Check/>}
                                </StepIndicator>
                            }
                            sx={{
                                '&::after': {
                                    ...(this.state.activeStep > step.order &&
                                        index !== 2 && {bgcolor: 'primary.solidBg'}),
                                },
                            }}
                            onClick={
                                this.props.lockedSteps.includes(step.order)?
                                    null:
                                    this.setStep.bind(this, step.order)
                            }
                        >
                            <StepButton>{step.title}</StepButton>
                        </Step>
                    ))}
                </Stepper>
            </>
        )
        if (this.state.activeStep === 0 && this.state.accounts) {
            return (
                <Stack
                    spacing={2}
                >
                    {stepper}
                    <CommonForm
                        key={'step-0-form'}
                        fields={[
                            {
                                name: 'account',
                                label: 'Компания',
                                xs: 12,
                                sm: null,
                                md: null,
                                lg: null,
                                xl: null,
                                required: true,
                                default: this.state.account,
                                type: 'select',
                                options: this.state.accounts.map((each) => {return(
                                    {'value': each.id, 'label': each.nameshort}
                                )})
                            },
                            {
                                type: 'submit',
                                label: 'Далее'
                            }
                        ]}
                        object={
                            {
                                account: this.state.account,
                            }
                        }
                        processForm={this.processStep0.bind(this)}
                    />
                </Stack>
            )
        }
        if (this.state.activeStep === 1 && this.state.opportunities) {
            return (
                <Stack
                    spacing={2}
                >
                    {stepper}
                    <Card>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'row'
                        }}
                        >
                            <CheckCircleIcon
                                color={'success'}
                                sx={{display: 'flex'}}
                            />
                            <Typography
                                sx={{display: 'flex'}}
                            >
                                Компания: {this.state.accountObj.nameshort}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Divider
                        sx={{
                            mb: 3,
                            mt: 3
                        }}
                    />
                    <CommonForm
                        key={'step-1-form'}
                        fields={[
                            {
                                'name': 'opportunity',
                                'label': 'Сделка',
                                'xs': 12,
                                'sm': null,
                                'md': null,
                                'lg': null,
                                'xl': null,
                                required: true,
                                empty: true,
                                'default': this.state.opportunity,
                                'type': 'select',
                                'options': this.state.opportunities.map((each) => {return(
                                    {'value': each.id, 'label': each.short_name}
                                )})
                            },
                            {
                                type: 'submit',
                                label: 'Далее'
                            }
                        ]}
                        object={
                            {
                                account: this.state.account,
                                opportunity: this.state.opportunity,
                            }
                        }
                        processForm={this.processStep1.bind(this)}
                    />
                </Stack>
            )
        }
        if (this.state.activeStep === 2 && this.state.account && this.state.phases ) {
            return (
                <Stack
                    spacing={2}
                >
                    {stepper}
                    <Card>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <CheckCircleIcon
                                color={'success'}
                                sx={{display: 'flex'}}
                            />
                            <Typography
                                sx={{display: 'flex'}}
                            >
                                Компания: {this.state.accountObj.nameshort}
                            </Typography>
                        </CardContent>
                    </Card>
                    {
                        this.state.opportunity?
                            <Card>
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <CheckCircleIcon
                                        color={'success'}
                                        sx={{display: 'flex'}}
                                    />
                                    <Typography
                                        sx={{display: 'flex'}}
                                    >
                                        Сделка: {this.state.opportunityObj.short_name}
                                    </Typography>
                                </CardContent>
                            </Card>:
                            <Card>
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <InfoIcon
                                        color={'primary'}
                                        sx={{display: 'flex'}}
                                    />
                                    <Typography
                                        sx={{display: 'flex'}}
                                    >
                                        Сделка не выбрана
                                    </Typography>
                                </CardContent>
                            </Card>
                    }
                    <Divider
                        sx={{
                            mb: 3,
                            mt: 3
                        }}
                    />
                    <CommonForm
                        key={'step-2-form'}
                        fields={
                            [
                                {
                                    'name': 'phase',
                                    'label': 'Этап трудоемкости',
                                    'xs': 12,
                                    'sm': null,
                                    'md': null,
                                    'lg': null,
                                    'xl': null,
                                    required: true,
                                    empty: true,
                                    'default': this.state.phase,
                                    'type': 'select',
                                    'options': this.state.phases.map((each) => {return(
                                        {'value': each.id, 'label': each.title}
                                    )})
                                },
                                {
                                    type: 'submit',
                                    label: 'Далее'
                                }
                            ]
                        }
                        processForm={this.processStep2.bind(this)}
                    />
                </Stack>
            )
        }
        if (this.state.activeStep === 3 && this.state.account && this.state.executors ) {
            return (
                <Stack
                    spacing={2}
                >
                    {stepper}
                    <Card>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <CheckCircleIcon
                                color={'success'}
                                sx={{display: 'flex'}}
                            />
                            <Typography
                                sx={{display: 'flex'}}
                            >
                                Компания: {this.state.accountObj.nameshort}
                            </Typography>
                        </CardContent>
                    </Card>
                    {
                        this.state.opportunity?
                            <Card>
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <CheckCircleIcon
                                        color={'success'}
                                        sx={{display: 'flex'}}
                                    />
                                    <Typography
                                        sx={{display: 'flex'}}
                                    >
                                        Сделка: {this.state.opportunityObj.short_name}
                                    </Typography>
                                </CardContent>
                            </Card>:
                            <Card>
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <InfoIcon
                                        color={'primary'}
                                        sx={{display: 'flex'}}
                                    />
                                    <Typography
                                        sx={{display: 'flex'}}
                                    >
                                        Сделка не выбрана
                                    </Typography>
                                </CardContent>
                            </Card>
                    }
                    {
                        this.state.phase?
                            <Card>
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <CheckCircleIcon
                                        color={'success'}
                                        sx={{display: 'flex'}}
                                    />
                                    <Typography
                                        sx={{display: 'flex'}}
                                    >
                                        Этап трудоемкости: {this.state.phaseObj.title}
                                    </Typography>
                                </CardContent>
                            </Card>
                            :
                            <Card>
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <InfoIcon
                                        color={'info'}
                                        sx={{display: 'flex'}}
                                    />
                                    <Typography
                                        sx={{display: 'flex'}}
                                    >
                                        Этап трудоемкости не выбран
                                    </Typography>
                                </CardContent>
                            </Card>
                    }
                    <Divider
                        sx={{
                            mb: 3,
                            mt: 3
                        }}
                    />
                    <CommonForm
                        key={'step-3-form'}
                        fields={[
                            {
                                'name': 'title',
                                'label': 'Название',
                                'xs': 12,
                                'sm': null,
                                'md': null,
                                'lg': null,
                                'xl': null,
                                'required': true,
                                'default': null,
                                'type': 'input',
                            },
                            {
                                'name': 'start_dt',
                                'label': 'Дата начала',
                                'xs': 12,
                                'sm': null,
                                'md': null,
                                'lg': null,
                                'xl': null,
                                'required': true,
                                'default': new Date(),
                                'type': 'date',
                            },
                            {
                                'name': 'end_dt',
                                'label': 'Дата окончания',
                                'xs': 12,
                                'sm': null,
                                'md': null,
                                'lg': null,
                                'xl': null,
                                'required': true,
                                'default': new Date(),
                                'type': 'date',
                            },
                            {
                                'name': 'duration_plan',
                                'label': 'Плановая трудоемкость (часов)',
                                'xs': 12,
                                'sm': null,
                                'md': null,
                                'lg': null,
                                'xl': null,
                                'required': true,
                                'default': 0,
                                'type': 'number',
                            },
                            {
                                'name': 'desc',
                                'label': 'Описание',
                                'xs': 12,
                                'sm': null,
                                'md': null,
                                'lg': null,
                                'xl': null,
                                'required': true,
                                'default': null,
                                'type': 'textarea',
                            },
                            {
                                'name': 'executor_m2m',
                                'label': 'Исполнители',
                                'xs': 12,
                                'sm': null,
                                'md': null,
                                'lg': null,
                                'xl': null,
                                'required': false,
                                'default': [],
                                'type': 'multiselect',
                                'options': this.state.executors.map((each) => {return(
                                    {
                                        'value': each.user.id,
                                        'label': `${each.user.first_name} ${each.user.last_name}`,
                                    }
                                )})
                            },
                            {
                                'name': 'important_bool',
                                'label': 'Важная',
                                'xs': 12,
                                'sm': null,
                                'md': null,
                                'lg': null,
                                'xl': null,
                                'required': false,
                                'default': false,
                                'type': 'checkbox',
                            },
                            {
                                type: 'submit',
                                label: 'Готово!'
                            }
                        ]}
                        object={null}
                        processForm={this.processStep3.bind(this)}
                    />
                </Stack>
            )
        }
        return (<CircularProgress/>)
    }
}

export default withNavigate(TaskCreate);