import React from 'react';
import {CircularProgress, Stack} from "@mui/joy";

// components
import CommonInfoTab from "../Shared/CommonInfoTab";
import BaseApi from "../../utils/BaseApi";
import RequisitesCreateModal from "./RequisitesCreateModal";
import UserSubscriberContext from "../../context/UserSubscriberContext";

// icons
import InfoIcon from "@mui/icons-material/Info";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import retrievePermission from "../../utils/retrievePermission";


class RequisitesTab extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            requisites: null,
            createPermission: 0,
            updatePermission: 0,
        };
    }

    async componentDidMount() {
        await this.retrieveRequisites();
        await this.retrievePermissions();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveRequisites();
        }
    }
    
    async retrievePermissions() {
        let c = await retrievePermission(
            'RequisitesViewSet',
            'create',
            this.context.subscriberObject
        )
        let u = await retrievePermission(
            'RequisitesViewSet',
            'update',
            this.context.subscriberObject
        )
        await this.setState(
            {
                createPermission: c,
                updatePermission: u,
            }
        )
    }

    async retrieveRequisites() {
        const url = 'requisites/';
        const api = new BaseApi();
        let params = {
            account: this.props.account.id
        }
        let response = await api.get(url, params);
        this.setState(
            {
                requisites: response.data.results,
            }
        );
    }

    render() {
        if (this.state.requisites === []) {
            return (
                <>
                    {
                        this.state.createPermission?
                            <RequisitesCreateModal
                                account={this.props.account}
                            />:
                            <CircularProgress/>
                    }
                </>
            )
        }
        if (this.state.requisites === null) {
            return (<CircularProgress/>)
        }
        return(
            <Stack
                spacing={2}
            >
                <CommonInfoTab
                    callBack={this.retrieveRequisites.bind(this)}
                    groups={
                        [
                            {
                                title: 'Основные данные',
                                icon: <InfoIcon/>,
                                fields: [
                                    {
                                        title: 'ИНН',
                                        value: this.state.requisites[0].inn,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['inn', ],
                                            baseUrl: 'requisites',
                                            obj: this.state.requisites[0]
                                        }
                                    },
                                    {
                                        title: 'КПП',
                                        value: this.state.requisites[0].kpp,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['kpp', ],
                                            baseUrl: 'requisites',
                                            obj: this.state.requisites[0]
                                        }
                                    },
                                    {
                                        title: 'ОГРН',
                                        value: this.state.requisites[0].ogrn,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['ogrn', ],
                                            baseUrl: 'requisites',
                                            obj: this.state.requisites[0]
                                        }
                                    },
                                ]
                            },
                            {
                                title: 'Банковские реквизиты',
                                icon: <AccountBalanceIcon />,
                                fields: [
                                    {
                                        title: 'Наименование банка',
                                        value: this.state.requisites[0].full_bank_name,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['full_bank_name', ],
                                            baseUrl: 'requisites',
                                            obj: this.state.requisites[0]
                                        }
                                    },
                                    {
                                        title: 'БИК',
                                        value: this.state.requisites[0].bik,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['bik', ],
                                            baseUrl: 'requisites',
                                            obj: this.state.requisites[0]
                                        }
                                    },
                                    {
                                        title: 'Корреспондентский счёт',
                                        value: this.state.requisites[0].correspondent_account,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['correspondent_account', ],
                                            baseUrl: 'requisites',
                                            obj: this.state.requisites[0]
                                        }
                                    },
                                    {
                                        title: 'Расчётный счёт',
                                        value: this.state.requisites[0].settlement_account,
                                        edit: {
                                            permission: this.state.updatePermission,
                                            fields: ['settlement_account', ],
                                            baseUrl: 'requisites',
                                            obj: this.state.requisites[0]
                                        }
                                    },
                                ]
                            },
                        ]
                    }
                />
            </Stack>
        )
    }
}

export default RequisitesTab