import React from 'react';

// components
import CommonInfoTab from "../Shared/CommonInfoTab";

// icons
import ContactPageIcon from '@mui/icons-material/ContactPage';


class ContactCard extends React.Component {
    render() {
        return(
            <CommonInfoTab
                groups={
                    [
                        {
                            title: `${this.props.contact.first_name} ${this.props.contact.patronymic} ${this.props.contact.last_name}`,
                            icon: <ContactPageIcon/>,
                            fields: [
                                {
                                    title: 'Должность',
                                    value: this.props.contact.role,
                                    linkPattern: '/contact/detail/:linkParam',
                                    linkParam: this.props.contact.id,
                                },
                                {
                                    title: 'Пол',
                                    value: this.props.contact.sex_human,
                                },
                            ]
                        }
                    ]
                }
            />
        )
    }
}

export default ContactCard