import React from 'react';

import Chip from "@mui/material/Chip";
import Box from "@mui/joy/Box";
import {CircularProgress, Modal, ModalClose, ModalDialog} from "@mui/joy";
import BaseApi from "../../utils/BaseApi";
import Button from "@mui/joy/Button";
import CommonForm from "../Shared/CommonForm";

// icons
import FastForwardIcon from '@mui/icons-material/FastForward';


class TaskDetailNextStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statuses: null,
            modalOpen: false,
        }
    }

    async componentDidMount(){
        await this.retrieveStatuses();
    }

    async setModalOpenTrue(){
        await this.setState({
            modalOpen: true,
        })
    }

    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
        await this.props.callBack();
    }

    async retrieveStatuses() {
        const url = `task/${this.props.taskId}/next_statuses/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                statuses: response.data
            }
        );
    }

    async processForm(form){
        const url = `task/${this.props.taskId}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            form.state.formData
        );
        if (response.status === 200){
            await this.setModalOpenFalse();
        }
    }

    render() {
        if (!this.state.statuses) {
            return(
                <CircularProgress/>
            )
        }
        if (this.state.statuses.length === 0) {
            return(
                <Chip
                    color={'warning'}
                    label={'Cмена статуса на данный момент не возможна'}
                />
            )
        }
        return(
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <Button
                    size={'sm'}
                    color="success"
                    sx={{mt: 0, mb: 1}}
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <FastForwardIcon sx={{mr: 1}}/> Сменить статус
                </Button>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                pr: 2
                            }}
                        >
                            <CommonForm
                                key={'step-2-form'}
                                fields={
                                    [
                                        {
                                            'name': 'work_status',
                                            'label': 'Следующий статус',
                                            'xs': 12,
                                            'sm': null,
                                            'md': null,
                                            'lg': null,
                                            'xl': null,
                                            'required': true,
                                            'default': this.state.statuses[0][0],
                                            'type': 'select',
                                            'options': this.state.statuses.map((each) => {return(
                                                {'value': each[0], 'label': each[1]}
                                            )})
                                        },
                                        {
                                            type: 'submit',
                                            label: 'Сохранить'
                                        }
                                    ]
                                }
                                processForm={this.processForm.bind(this)}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default TaskDetailNextStatus