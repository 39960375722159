import React from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import BaseApi from "../../utils/BaseApi";

import TaskCard from "./TaskCard";
import Grid from "@mui/joy/Grid";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import Box from "@mui/joy/Box";
import {Stack} from "@mui/joy";
import CommonFilterModal from "../Shared/CommonFilterModal";


class TaskMyBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tasksPosed: [],
            tasksWork: [],
            tasksOnaccept: [],
            tasksCompleted: [],

        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    async componentDidMount() {
        await this.retrieveAllTasks();
    }

    async retrieveAllTasks() {
        let tasksPosed = await this.retrievePosedTasks();
        let tasksWork = await this.retrieveWorkTasks();
        let tasksOnaccept = await this.retrieveOnacceptTasks();
        let tasksCompleted = await this.retrieveCompletedTasks();
        await this.setState(
            {
                tasksPosed: tasksPosed,
                tasksWork: tasksWork,
                tasksOnaccept: tasksOnaccept,
                tasksCompleted: tasksCompleted,
            }
        );
    }

    async retrievePosedTasks() {
        const url = 'task/my/';
        const api = new BaseApi();
        const params = {
            work_status: 'PSD'
        };
        let response = await api.get(
            url,
            params
        );
        if (response.status === 200){
            return response.data.results
        }
        return []
    }

    async retrieveWorkTasks() {
        const url = 'task/my/';
        const api = new BaseApi();
        const params = {
            work_status: 'WRK'
        };
        let response = await api.get(
            url,
            params
        );
        if (await response.status === 200){
            return await response.data.results
        }
        return []
    }

    async retrieveOnacceptTasks() {
        const url = 'task/my/';
        const api = new BaseApi();
        const params = {
            work_status: 'OAC'
        };
        let response = await api.get(
            url,
            params
        );
        if (await response.status === 200){
            return await response.data.results
        }
        return []
    }

    async retrieveCompletedTasks() {
        const url = 'task/my/';
        const api = new BaseApi();
        const params = {
            work_status: 'COM',
            page_size: 10,
            ordering: '-end_dt'
        };
        let response = await api.get(
            url,
            params
        );
        if (await response.status === 200){
            return await response.data.results
        }
        return []
    }

    async retrieveTask(id){
        const url = 'task/'+id+'/';
        const api = new BaseApi();
        let response = await api.get(url);
        return response
    }

    async updateTask(id, data){
        const url = 'task/'+id+'/';
        const api = new BaseApi();
        let result = await api.patch(
            url,
            data
        );
        return result
    }

    // TODO: Add splice for task when dragging
    // ar = ar.filter(item => !(item > 3));

    async onDragEnd(result) {
        const regex = new RegExp("(task)(\\d+)");
        let taskId = result.draggableId.match(regex)[2];
        switch (result.source.droppableId) {
            case 'droppable-posed':
                if (result.destination.droppableId !== 'droppable-posed') {
                    this.setState(
                        {
                            'tasksPosed': this.state.tasksPosed.filter(
                                item => (item.id !== Number(taskId))
                            )
                        }
                    );
                }
                break
            case 'droppable-work':
                if (result.destination.droppableId !== 'droppable-work') {
                    this.setState(
                        {
                            'tasksWork': this.state.tasksWork.filter(
                                item => (item.id !== Number(taskId))
                            )
                        }
                    );
                }
                break
            case 'droppable-onaccept':
                if (result.destination.droppableId !== 'droppable-onaccept') {
                    this.setState(
                        {
                            'tasksOnaccept': this.state.tasksOnaccept.filter(
                                item => (item.id !== Number(taskId))
                            )
                        }
                    );
                }
                break
            case 'droppable-completed':
                if (result.destination.droppableId !== 'droppable-completed') {
                    this.setState(
                        {
                            'tasksCompleted': this.state.tasksCompleted.filter(
                                item => (item.id !== Number(taskId))
                            )
                        }
                    );
                }
                break
            default:
                console.log('Invalid source');
                break
        }
        let task = await this.retrieveTask(taskId);
        if (task.status === 200){
            switch (task.data.work_status){
                case 'PSD':
                    if (result.destination.droppableId === 'droppable-work') {
                        await this.updateTask(
                            taskId,
                            {
                                work_status: 'WRK'
                            }
                        )
                        let tasks_posed = await this.retrievePosedTasks();
                        let tasks_work = await this.retrieveWorkTasks();
                        await this.setState(
                            {
                                tasks_posed: tasks_posed,
                                tasks_work: tasks_work,

                            }
                        );
                        return
                    }
                    else {
                        console.log('Invalid destination: ' + result.destination.droppableId);
                        return
                    }
                case 'WRK':
                    switch (result.destination.droppableId){
                        case 'droppable-onaccept':
                            if (task.data.executor.id === task.data.owner.id){
                                await this.updateTask(
                                    taskId,
                                    {
                                        work_status: 'COM',
                                    }
                                )
                                let tasksWork = await this.retrieveWorkTasks();
                                let tasksCompleted = await this.retrieveCompletedTasks();
                                await this.setState(
                                    {
                                        tasksWork: tasksWork,
                                        tasksCompleted: tasksCompleted,

                                    }
                                );
                                return
                            }
                            else {
                                await this.updateTask(
                                    taskId,
                                    {
                                        work_status: 'OAC'
                                    }
                                )
                                let tasksWork = await this.retrieveWorkTasks();
                                let tasksOnaccept = await this.retrieveOnacceptTasks();
                                await this.setState(
                                    {
                                        tasksWork: tasksWork,
                                        tasksOnaccept: tasksOnaccept,

                                    }
                                );
                                return
                            }
                        case 'droppable-completed':
                            if (task.data.executor.id === task.data.owner.id){
                                await this.updateTask(
                                    taskId,
                                    {
                                        work_status: 'COM'
                                    }
                                )
                                let tasksWork = await this.retrieveWorkTasks();
                                let tasksCompleted = await this.retrieveCompletedTasks();
                                await this.setState(
                                    {
                                        tasksWork: tasksWork,
                                        tasksCompleted: tasksCompleted,

                                    }
                                );
                                return
                            }
                            else {
                                await this.updateTask(
                                    taskId,
                                    {
                                        work_status: 'OAC'
                                    }
                                )
                                let tasksWork = await this.retrieveWorkTasks();
                                let tasksCompleted = await this.retrieveCompletedTasks();
                                await this.setState(
                                    {
                                        tasksWork: tasksWork,
                                        tasksCompleted: tasksCompleted,

                                    }
                                );
                                return
                            }
                        default:
                            console.log('Invalid destination: ' + result.destination.droppableId);
                            return
                    }
                case 'OAC':
                    console.log('Not your action');
                    return
                case 'COM':
                    console.log('Last task state');
                    return
                default:
                    console.log('Invalid destination: ' + result.destination.droppableId);
                    return
            }
        }
    }

    render() {
        return (
            <Stack
                spacing={2}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography
                        level={'h3'}
                    >
                        Моя доска задач
                    </Typography>
                </Stack>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            xs={12}
                            sm={6}
                            md={3}
                            sx={
                                {
                                    alignContent: 'space-between',
                                    display: 'flex',
                                    flexWrap: 'wrap',

                                }
                            }
                        >
                            <Typography level="title-lg" sx={{p: 2}}>
                                Поставленные
                            </Typography>
                            <Box sx={{width: '100%',}}>
                                <Divider inset="none" sx={{ mt: 2, mb: 2 }} />
                            </Box>
                            <Droppable droppableId="droppable-posed">
                                {(provided, snapshot) => (
                                    <Sheet
                                        sx={
                                            {
                                                height: '75dvh',
                                                alignContent: 'flex-start',
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                overflow: 'auto',
                                                width: '100%',
                                            }
                                        }
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {
                                            this.state.tasksPosed.map(
                                                (task, index) => (
                                                    <Draggable key={'task'+task.id} draggableId={'task'+task.id} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <TaskCard
                                                                    task={task}
                                                                    callBack={this.retrieveAllTasks.bind(this)}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            )
                                        }
                                        {provided.placeholder}
                                    </Sheet>
                                )}
                            </Droppable>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            md={3}
                            sx={
                                {
                                    alignContent: 'space-between',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                }
                            }
                        >
                            <Typography level="title-lg" sx={{p: 2}}>
                                    В работе
                            </Typography>
                            <Box sx={{width: '100%',}}>
                                <Divider inset="none" sx={{ mt: 2, mb: 2 }} />
                            </Box>
                            <Droppable droppableId="droppable-work">
                                {(provided, snapshot) => (
                                    <Sheet
                                        sx={
                                            {
                                                height: '75dvh',
                                                alignContent: 'flex-start',
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                overflow: 'auto',
                                                width: '100%',
                                            }
                                        }
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {
                                            this.state.tasksWork.map(
                                                (task, index) => (
                                                    <Draggable key={'task'+task.id} draggableId={'task'+task.id} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <TaskCard
                                                                    task={task}
                                                                    callBack={this.retrieveAllTasks.bind(this)}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            )
                                        }
                                        {provided.placeholder}
                                    </Sheet>
                                )}
                            </Droppable>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            md={3}
                            sx={
                                {

                                    alignContent: 'space-between',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                }
                            }
                        >
                            <Typography level="title-lg" sx={{p: 2}}>
                                    На приемке
                            </Typography>
                            <Box sx={{width: '100%',}}>
                                <Divider inset="none" sx={{ mt: 2, mb: 2 }} />
                            </Box>
                            <Droppable droppableId="droppable-onaccept">
                                {(provided, snapshot) => (
                                    <Sheet
                                        sx={
                                            {
                                                height: '75dvh',
                                                alignContent: 'flex-start',
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                overflow: 'auto',
                                                width: '100%',
                                            }
                                        }
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {
                                            this.state.tasksOnaccept.map(
                                                (task, index) => (
                                                    <Draggable key={'task'+task.id} draggableId={'task'+task.id} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <TaskCard
                                                                    task={task}
                                                                    callBack={this.retrieveAllTasks.bind(this)}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            )
                                        }
                                        {provided.placeholder}
                                    </Sheet>
                                )}
                            </Droppable>
                        </Grid>
                        <Grid
                            xs={12}
                            sm={6}
                            md={3}
                            sx={
                                {

                                    alignContent: 'space-between',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                }
                            }
                        >
                            <Typography level="title-lg" sx={{p: 2}}>
                                Завершены (последние 10)
                            </Typography>
                            <Box sx={{width: '100%',}}>
                                <Divider inset="none" sx={{ mt: 2, mb: 2 }} />
                            </Box>
                            <Droppable droppableId="droppable-completed">
                                {(provided, snapshot) => (
                                    <Sheet
                                        sx={
                                            {
                                                height: '75dvh',
                                                alignContent: 'flex-start',
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                overflow: 'auto',
                                                width: '100%',
                                            }
                                        }
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {
                                            this.state.tasksCompleted.map(
                                                (task, index) => (
                                                    <Draggable key={'task'+task.id} draggableId={'task'+task.id} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <TaskCard
                                                                    task={task}
                                                                    callBack={this.retrieveAllTasks.bind(this)}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            )
                                        }
                                        {provided.placeholder}
                                    </Sheet>
                                )}
                            </Droppable>
                        </Grid>
                    </Grid>
                </DragDropContext>
            </Stack>
        )
    }
}


export default TaskMyBoard;
