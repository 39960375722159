import React from "react";
import BaseApi from "../../utils/BaseApi";
import {CircularProgress, Table} from "@mui/joy";
import {TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import Chip from "@mui/material/Chip";
import TeamCreate from "./TeamCreate";
import DeleteIcon from "@mui/icons-material/Delete";
import Link from "@mui/joy/Link";
import retrievePermission from "../../utils/retrievePermission";
import UserSubscriberContext from "../../context/UserSubscriberContext";

class TeamTab extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            team: null,
            destroyPermission: 0,
        };
    }

    async componentDidMount() {
        await this.retrieveTeam();
        await this.retrieveDestroyPermission();
    }

    async retrieveDestroyPermission() {
        let p = await retrievePermission(
            'OppTeamViewSet',
            'destroy',
            this.context.subscriberObject
        )
        await this.setState(
            {
                destroyPermission: p,
            }
        )
    }

    async retrieveTeam() {
        const url = `opp_team/`;
        const api = new BaseApi();
        const params = {
            opportunity: this.props.opportunityId
        };
        let response = await api.get(url, params);
        this.setState(
            {
                team: response.data.results
            }
        );
    }

    async destroy(id) {
        const url = `opp_team/${id}/`;
        const api = new BaseApi();
        await api.delete(url, {});
        await this.retrieveTeam();
    }

    render() {
        if (!this.state.team) {
            return (<CircularProgress/>)
        }
        return (
            <>
                <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    hoverRow
                    sx={{
                        '--TableCell-headBackground': (theme) =>
                            theme.vars.palette.background.level1,
                        '--Table-headerUnderlineThickness': '1px',
                        '--TableRow-hoverBackground': (theme) =>
                            theme.vars.palette.background.level1,
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Сотрудник
                            </TableCell>
                            <TableCell>
                                Роль
                            </TableCell>
                            <TableCell>
                                Загрузка
                            </TableCell>
                            <TableCell>
                                Добавлен
                            </TableCell>
                            <TableCell>

                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.team.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>
                                {row.subscriber.user.first_name} {row.subscriber.user.last_name}
                            </TableCell>
                            <TableCell>
                                <Chip color={row.color} size={'small'} label={row.role_human} />
                            </TableCell>
                            <TableCell>
                                {row.load_days}
                            </TableCell>
                            <TableCell>
                                {row.created_on}
                            </TableCell>
                            <TableCell>
                                {this.state.destroyPermission > 0 && row.load_days === 0?
                                    <Link
                                        level="body-xs"
                                        color={'danger'}
                                        onClick={this.destroy.bind(this, row.id)}
                                    >
                                        <DeleteIcon sx={{mr: 1}}/>
                                        Удалить
                                    </Link>
                                    :null
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                <TeamCreate
                    callBack={this.retrieveTeam.bind(this)}
                    opportunityId={this.props.opportunityId}
                />
            </>
        )
    }
}

export default TeamTab