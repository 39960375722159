import React from "react";
import BaseApi from "../../utils/BaseApi";
import Tooltip from "@mui/joy/Tooltip";
import {CircularProgress} from "@mui/joy";

//Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import Chip from "@mui/material/Chip";
import UserSubscriberContext from "../../context/UserSubscriberContext";


class CommentRead extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            read_by: null,
        };
    }

    async componentDidMount() {
        await this.postReadBy()
    }

    async postReadBy() {
        const url = `comment/${this.props.commentID}/`;
        const data = {
            'read_by': [this.context.userObject.id,]
        };
        const api = new BaseApi();
        let response = await api.patch(url, data);
        this.setState(
            {read_by: response.data.read_by}
        )
    }

    render() {
        if (!this.state.read_by) {
            return (
                <CircularProgress
                    size={'sm'}
                />
            )
        }
        return (
            <Tooltip
                title={
                    this.state.read_by.map((each) => {return(`${each.first_name} ${each.last_name}`)}).join(', ')
                }
                variant="soft"
            >
                <Chip
                    icon={<VisibilityIcon />}
                    label={this.state.read_by.length}
                    size='small'
                    sx={{
                        mr: 2,
                    }}
                />
            </Tooltip>
        )
    }
}

export default CommentRead
