import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress, Stack} from "@mui/joy";
import Box from '@mui/joy/Box';
import Typography from "@mui/joy/Typography";

// components
import DeliveryXtypeAdmin from "./DeliveryXtypeAdmin";


class DeliveryAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: null,
            pages: null,
        };
    }

    async componentDidMount() {
        await this.retrieveXtypes();
    }

    async retrieveXtypes() {
        const url = `attachment/subscriber_xtypes`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        let categories = []
        for (const category of response.data.all_xtypes) {
            if (response.data.available_xtypes.includes(category[0])){
                categories.push(category);
            }
        }
        await this.setState(
            {
                categories: categories,
            }
        );
    }

    render() {
        if (!this.state.categories) {
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1,
                    gap: 1,
                    flexWrap: 'wrap',
                    '& > *': {
                        minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                        flexGrow: 1,
                    },
                }}
            >
                <Stack
                    direction={'column'}
                >
                    {this.state.categories.map(each => {
                        return(
                            <>
                                <Typography level={'h1'}>
                                    {each[1]}
                                </Typography>
                                <Box>
                                    <DeliveryXtypeAdmin
                                        xtype={each[0]}
                                    />
                                </Box>
                            </>
                        )
                    })}
                </Stack>
            </Box>
        )
    }
}

export default DeliveryAdmin