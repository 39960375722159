import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";

import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import KpiCard from "./KpiCard";


function getWeekNumber(d) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    let weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    return weekNo - 1;
}


class KpiMyList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            report: [],
        };
        switch (this.props.kpi.aggregation_period) {
            case 'MONTH':
                this.activeCard = new Date().getMonth();
                break
            case 'WEEK':
                this.activeCard = getWeekNumber(new Date());
                break
            default:
                this.activeCard = 0;
                break
        }
    }

    async componentDidMount() {
        await this.retrieveReport();
    }

    async retrieveReport() {
        const url = 'kpi/report/';
        const api = new BaseApi();
        let response = await api.get(
            url,
            {
                subscriber: this.props.subscriber,
                kpi: this.props.kpi.id,
            }
        );
        this.setState(
            {
                report: response.data
            }
        );
    }

    render() {
        if (!this.state.report) {
            return (<CircularProgress/>)
        }
        return (
            <>
                <Typography
                    level={'h3'}
                >
                    {this.props.kpi.title}
                </Typography>
                <Box>
                    <Swiper
                        modules={[Navigation, Pagination]}
                        spaceBetween={0}
                        navigation
                        pagination={{clickable: true}}
                        // onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => swiper.slideTo(this.activeCard)}
                        breakpoints={{
                            1181: {
                                slidesPerView: 4
                            },
                            1180: {
                                slidesPerView: 3
                            },
                            1020: {
                                slidesPerView: 2
                            },
                            700: {
                                slidesPerView: 1
                            }
                        }}
                    >
                        {this.state.report.map((row) => {
                            return (
                                <SwiperSlide
                                    key={`${this.props.kpi.id}-${row.period}`}

                                >
                                    <KpiCard
                                        kpi={row}
                                    />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </Box>
            </>
        )
    }
}

export default KpiMyList