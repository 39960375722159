import React from 'react';

import Chip from "@mui/material/Chip";
import Box from "@mui/joy/Box";
import {CircularProgress, Modal, ModalClose, ModalDialog} from "@mui/joy";
import BaseApi from "../../utils/BaseApi";
import Button from "@mui/joy/Button";
import CommonForm from "../Shared/CommonForm";

// icons
import FastForwardIcon from '@mui/icons-material/FastForward';


class TaskDetailDelegate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            subordinates: null,
        }
    }

    async componentDidMount() {
        await this.retrieveSubordinates();
    }

    async retrieveSubordinates() {
        const url = 'subscriber/descendants/';
        const api = new BaseApi();
        let response = await api.get(
            url,{}
        );
        this.setState({
            subordinates: response.data.results
        })
    }

    async setModalOpenTrue(){
        await this.setState({
            modalOpen: true,
        })
    }

    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
        await this.props.callBack();
    }

    async processForm(form){
        const url = `task/${this.props.taskId}/delegate/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            form.state.formData
        );
        if (response.status === 200){
            await this.setModalOpenFalse();
        }
    }

    render() {
        if (!this.state.subordinates) {
            return(
                <CircularProgress/>
            )
        }
        if (this.state.subordinates.length === 0) {
            return(
                <Chip
                    color={'warning'}
                    label={'Делегирование не возможно'}
                />
            )
        }
        return(
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <Button
                    size={'sm'}
                    color="success"
                    sx={{mt: 0, mb: 1}}
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <FastForwardIcon sx={{mr: 1}}/> Делегировать
                </Button>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                pr: 2
                            }}
                        >
                            <CommonForm
                                key={'step-2-form'}
                                fields={
                                    [
                                        {
                                            'name': 'new_executor',
                                            'label': 'Исполнитель',
                                            'xs': 12,
                                            'sm': null,
                                            'md': null,
                                            'lg': null,
                                            'xl': null,
                                            'required': true,
                                            'default': this.state.subordinates[0].id,
                                            'type': 'select',
                                            'options': this.state.subordinates.map((each) => {
                                                return (
                                                    {
                                                        'value': each.id,
                                                        'label': `${each.user?.first_name} ${each.user?.last_name}`
                                                    }
                                                )
                                            })
                                        },
                                        {
                                            type: 'submit',
                                            label: 'Делегировать'
                                        }
                                    ]
                                }
                                processForm={this.processForm.bind(this)}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default TaskDetailDelegate