import React from "react";
import Typography from "@mui/joy/Typography";
import {Stack} from "@mui/joy";


class FeatureRequestDetailHeader extends React.Component {
    render() {
        return(
            <Stack>
                <Typography level={'h4'} sx={{mb: 1}}>
                    {this.props.featureRequest.title}
                </Typography>
                <Typography>
                    <strong>Автор:</strong> {this.props.featureRequest.owner?.first_name} {this.props.featureRequest.owner?.last_name}
                </Typography>
            </Stack>
        )
    }
}

export default FeatureRequestDetailHeader