import React from "react";

import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import {stateToHTML} from 'draft-js-export-html';
import MUIRichTextEditor from '../MuiRte/MUIRichTextEditor.tsx'
import Box from "@mui/joy/Box";
import ProfileHorizontalByProps from "../Profile/ProfileHorizontalByProps";


class CommentForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mention_process: false,
            mention_filter: '',
            users: null,
            value: ''
        };
    }

    componentDidCatch(error, errorInfo) {
        this.forceUpdate();
    }

    async retrieveUsers() {
        const url = 'user/';
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        )
        this.setState(
            {
                users: response.data.results.map(each => {
                    return (
                        {
                            keys: [each.username],
                            value: '@' + each.username,
                            content: <ProfileHorizontalByProps
                                user={each}
                            />,
                        }
                    )
                })
            }
        );
    }

    async componentDidMount() {
        await this.retrieveUsers();
    }

    componentDidUpdate(prevProps) {
        if (this.props.initialValue !== prevProps.initialValue) {
            this.setState({value: this.props.initialValue ?? ''})
        }
    }

    handleEditorChange(state) {
        this.setState(
            {
                value: stateToHTML(state.getCurrentContent()),
            }
        );
    }

    render() {
        if (!this.state.users) {
            return (<CircularProgress />)
        }
        return(
            <Box
                sx={{
                    height: '200px',
                    overflowY: 'scroll',
                }}
            >
                <MUIRichTextEditor
                    id={'comment-form'}
                    controls={[
                        "title",
                        "bold",
                        "italic",
                        "numberList",
                        "bulletList",
                        "clear",
                    ]}
                    defaultValue={''}
                    label="Start typing..."
                    onChange={this.handleEditorChange.bind(this)}
                    autocomplete={{
                        strategies: [
                            {
                                items: this.state.users,
                                triggerChar: "@",
                                insertSpaceAfter: true
                            },
                        ]
                    }}
                />
            </Box>
        )
    }
}

export default CommentForm;