import React from "react";
import BaseApi from "../../utils/BaseApi";

// icons
import DeleteIcon from '@mui/icons-material/Delete';
import UserSubscriberContext from "../../context/UserSubscriberContext";


class CommentDelete extends React.Component {
    static contextType = UserSubscriberContext

    async deleteComment() {
        const url = 'comment/' + this.props.comment.id + '/';
        const api = new BaseApi();
        const data = {
            deleted_bool: true,
        };
        await api.patch(url, data);
        this.props.callBack();
    }

    render() {
        if (this.context.userObject.id === this.props.comment.owner.id) {
            return (
                <DeleteIcon
                    color={'danger'}
                    onClick={this.deleteComment.bind(this)}
                    size={'small'}
                    sx={{
                        mr: 1,
                        mt: '2px',
                        "&:hover": {
                            cursor: 'pointer',
                        }
                    }}
                />
            )

        } else {
            return (<></>)
        }
    }
}

export default CommentDelete