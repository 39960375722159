import React from 'react';
import dayjs from "dayjs";

import {CardOverflow, CircularProgress} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";
import Box from "@mui/joy/Box";
import Chip from "@mui/material/Chip";
import Link from "@mui/joy/Link";

// components
import withNavigate from "../../utils/withNavigate";
import ProfileHorizontalByProps from "../Profile/ProfileHorizontalByProps";
import TaskDetail from "./TaskDetail";

// icons
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';


class TaskCard extends React.Component {
    render() {
        if (!this.props.task) {
            return (<CircularProgress/>)
        }
        return (
            <Card
                orientation="horizontal"
                variant="outlined"
                sx={{mb: 1}}
            >
                <CardOverflow
                    variant='soft'
                    color={this.props.task.work_status_color}
                    sx={{
                        px: 0.2,
                        writingMode: 'vertical-lr',
                        transform: 'rotate(180deg)',
                        textAlign: 'center',
                        fontSize: 'xs',
                        fontWeight: 'xl',
                        letterSpacing: '1px',
                        textTransform: 'uppercase',
                        borderLeft: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    {this.props.task.work_status_human}
                </CardOverflow>
                <CardContent>
                    <Box
                        sx={{
                            display: 'flex'
                        }}
                    >
                        <Typography
                            level={'title-md'}
                            sx={{
                                mb: 1,
                                display: 'inline',
                            }}
                        >
                            {this.props.task.title} для&nbsp;
                            <Link onClick={() => this.props.navigate(`/account/detail/${this.props.task.account.id}`)}>
                                {this.props.task.account.nameshort}
                            </Link>
                        </Typography>
                        <TaskDetail
                            taskId={this.props.task.id}
                            ref={this.taskDetails}
                            callBack={this.props.callBack}
                        />
                    </Box>
                    {
                        this.props.task.opportunity ?
                        <Typography
                            level={'title-md'}
                            sx={{'mb': 1}}
                        >
                            к сделке&nbsp;
                            <Link onClick={() => this.props.navigate(`/opportunity/detail/${this.props.task.opportunity.id}`)}>
                                {this.props.task.opportunity.short_name}
                            </Link>
                        </Typography>:
                        null
                    }
                    <Box>
                        <ProfileHorizontalByProps
                            user={this.props.task.owner}
                        />
                        <ArrowRightAltIcon
                            sx={
                                {
                                    'mr': 1,
                                    'display': 'inline-block',
                                    'verticalAlign': 'middle',
                                }
                            }
                        />
                        <ProfileHorizontalByProps
                            user={this.props.task.executor}
                        />
                        <Chip
                            sx={{'mb': 2, 'mt': 2}}
                            color={this.props.task.status === 'COM'?'success':dayjs(this.props.task.end_dt)>dayjs()?'success':'error'}
                            label={'до ' + dayjs(this.props.task.end_dt).format('DD.MM.YYYY')}
                        />
                    </Box>
                </CardContent>
            </Card>
        )
    }
}


export default withNavigate(TaskCard);
