import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import RouteTable from "./RouteTable";


class RouteList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            routes: [],
        };
    }

    async componentDidMount() {
        await this.retrieveRoutes();
    }

    async retrieveRoutes() {
        const url = 'route_state/my/';
        const api = new BaseApi();
        let response = await api.get(url, {});
        await this.setState(
            {
                routes: response.data.results
            }
        );
    }

    render() {
        if (!this.state.routes) {
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1,
                    gap: 1,
                    flexWrap: 'wrap',
                    '& > *': {
                        minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                        flexGrow: 1,
                    },
                }}
            >
                <Typography
                    level="h1"
                    fontSize="xl4"
                    sx={{'pb': 2}}
                >
                    Мои маршруты
                </Typography>
                <RouteTable
                    routes={this.state.routes}
                    callBack={this.retrieveRoutes}
                />
            </Box>
        )
    }
}

export default RouteList;
