import axios from "axios";
import {jwtDecode} from "jwt-decode";
import dayjs from "dayjs";
import * as qs from 'qs'


class BaseApi {
    constructor() {
        this.baseHost = process.env.REACT_APP_API_HOST;
        this.baseUrl = `${this.baseHost}/api/v2/`;
        this.token = null;
    }

    async get_token() {
        let authTokens = localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens")) : null;
        if (authTokens) {
            const user = jwtDecode(authTokens.access)
            const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
            if (!isExpired) {
                return `Bearer ${authTokens.access}`
            }
            await axios.post(
                `${this.baseUrl}auth/token/refresh/`,
                {
                    refresh: authTokens.refresh
                }
            ).then(
                function (response) {
                    authTokens.access = response.data.access;
                    localStorage.setItem('authTokens', JSON.stringify(authTokens));
                    return `Bearer ${response.data.access}`
                }
            ).catch(function (error) {
                if (error.response.status === 500) {
                    localStorage.removeItem('authTokens');
                    console.log("Неполадки на сервере");
                } else if (error.response.status === 401) {
                    localStorage.removeItem('authTokens');
                    console.log("введен неккоректное имя пользователя или пароль");
                } else if (error.response.status === 403) {
                    localStorage.removeItem('authTokens');
                    console.log("Токен закончился");
                } else {
                    localStorage.removeItem('authTokens');
                    console.log(error.response.status);
                }
            })
        }
    }

    async get(url, params) {
        try {
            const token = await this.get_token();
            const response = await axios.get(
                `${this.baseUrl}${url}`,
                {
                    headers: {
                        'Authorization': token
                    },
                    params: params,
                    paramsSerializer: params => {
                        return qs.stringify(params)
                    }
                }
              )
            return {
                'status': response.status,
                'data': response.data,
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401){
                    await this.get_token();
                    return this.get(url, params);
                }else if (error.response.status === 403) {
                    if (error.response.data.detail === 'Actions is not allowed.'){
                        return {
                            'status': error.response.status,
                            'data': error.response.data,
                        }
                    }
                    else {
                        await this.get_token();
                        return this.get(url, params);
                    }
                }
                else if (error.response.status < 500) {
                    return {
                        'status': error.response.status,
                        'data': error.response.data,
                    }
                }
                else {
                    console.error(error);
                }
            }
        }
    }

    async post(url, data, multipart=false) {
        const token = await this.get_token();
        let headers = {
            'Authorization': token
        };
        if (multipart) {
            headers['Content-Type'] =  'multipart/form-data'
        }
        try {
            const response = await axios.post(
                `${this.baseUrl}${url}`,
                data,
                {
                    headers: headers
                }
            );
            return {
                'status': response.status,
                'data': response.data,
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401){
                    await this.get_token();
                    return this.post(url, data);
                }
                else if (error.response.status < 500) {
                    return {
                        'status': error.response.status,
                        'data': error.response.data,
                    }
                }
                else {
                    console.error(error);
                }
            }
        }
    }

    async patch(url, data, multipart=false) {
        const token = await this.get_token();
        let headers = {
            'Authorization': token
        };
        if (multipart) {
            headers['Content-Type'] =  'multipart/form-data'
        }
        try {
            const response = await axios.patch(
                `${this.baseUrl}${url}`,
                data,
                {
                    headers: headers
                }
            );
            return {
                'status': response.status,
                'data': response.data,
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401){
                    await this.get_token();
                    return this.patch(url, data);
                }
                if (error.response.status < 500) {
                    return {
                        'status': error.response.status,
                        'data': error.response.data,
                    }
                }
                else {
                    console.error(error);
                }
            }
        }
    }

    async delete(url) {
        try {
            const token = await this.get_token();
            const response = await axios.delete(
                `${this.baseUrl}${url}`,
                {
                    headers: {
                        'Authorization': token
                    }
                }
            );
            return {
                'status': response.status,
                'data': response.data,
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401){
                    await this.get_token();
                    return this.delete(url);
                }
                if (error.response.status < 500) {
                    return {
                        'status': error.response.status,
                        'data': error.response.data,
                    }
                }
                else {
                    console.error(error);
                }
            }
        }
    }
}

export default BaseApi