import React from "react";
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import TaskCard from "../Task/TaskCard";


class TaskSearchCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            task: null,
        }
    }

    async retrieveTask() {
        const url = 'task/' + this.props.taskId;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState({
            task: response.data,
        });
    }

    async componentDidMount() {
        await this.retrieveTask();
    }

    render() {
        if (!this.state.task) {
            return (<CircularProgress/>)
        }
        return (
            <TaskCard
                task={this.state.task}
            />
        )
    }
}

export default TaskSearchCard