import BaseApi from "./BaseApi";

async function retrievePermission(viewset, action, subscriberObject){
    const url = `api_permission/`;
    const api = new BaseApi();
    let subscriberSupertypes = subscriberObject.subscriber_type.map(each => {
        return(
            each.super_type
        )
    });
    let permission_count = 0;
    for (const supertype of subscriberSupertypes){
        let params = {
            viewset: viewset,
            action: action,
            super_type: supertype
        };
        let response = await api.get(
            url,
            params
        );
        permission_count += response.data.count
    }
    return permission_count
}

export default retrievePermission