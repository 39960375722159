import React from "react";
import BaseApi from "../../utils/BaseApi";
import {Modal, ModalClose, ModalDialog, Stack} from "@mui/joy";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";


// icons
import AddCircleIcon from "@mui/icons-material/AddCircle";

// components
import CommonForm from "../Shared/CommonForm";


class EstimationRevisionCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            formFields: null,
        };
    }

    async retrieveFields(){
        const url = `estimation_revision/create_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        return(response.data)
    }

    async setModalOpenTrue(){
        let formFields = await this.retrieveFields();
        await this.setState({
            modalOpen: true,
            formFields: formFields,
        })
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        formDataObj.opportunity = this.props.opportunityId;
        const url = `estimation_revision/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 201) {
            await this.setModalOpenFalse();
        }
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
        this.props.callBack();
    }

    render() {
        return (
            <Stack
            >
                <Button
                    size={'sm'}
                    variant={'soft'}
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <AddCircleIcon sx={{mr: 1}}/> Добавить
                </Button>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                height: 'calc(80vh)',
                                pr: 2
                            }}
                        >
                            <Typography
                                level={'h2'}
                                sx={{mb: 2}}
                            >
                                Обновление ревизии трудоемкости
                            </Typography>
                            <CommonForm
                                fields={this.state.formFields}
                                object={this.props.revision}
                                processForm={this.processForm.bind(this)}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Stack>
        )
    }
}

export default EstimationRevisionCreate
