import React from 'react';
import Typography from "@mui/joy/Typography";
import {Modal, ModalClose, ModalDialog, Stack} from "@mui/joy";
import BaseApi from "../../utils/BaseApi";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";

// icons
import EditIcon from '@mui/icons-material/Edit';

// components
import CommonForm from "../Shared/CommonForm";
import retrievePermission from "../../utils/retrievePermission";
import UserSubscriberContext from "../../context/UserSubscriberContext";


class OpportunityName extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            modalOpen: false,
            permission: 0,
        };
    }

    async componentDidMount(){
        await this.retrievePermission();
    }

    async setModalOpenTrue(){
        await this.setState({
            modalOpen: true,
        })
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
    }

    async retrievePermission() {
        let p = await retrievePermission(
            'OpportunityViewSet',
            'partial_update',
            this.context.subscriberObject
        );
        await this.setState(
            {
                permission: p,
            }
        );
    }

    async processForm(form) {
        const url = `opportunity/${this.props.opportunityId}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            form.state.formData
        );
        if (response.status === 200) {
            this.setState(
                {
                    name: response.data.name,
                    modalOpen: false,
                }
            );
        }
    }

    render() {
        return(
            <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
            >
                <Typography level={'body-md'}>
                    Полное название: {this.state.name}
                </Typography>
                {
                    this.state.permission > 0?
                        <>
                            <IconButton
                                size="sm"
                                color="primary"
                                onClick={this.setModalOpenTrue.bind(this)}
                            >
                                <EditIcon/>
                            </IconButton>
                            <Modal
                                open={this.state.modalOpen}
                            >
                                <ModalDialog
                                    layout={'center'}
                                    size="lg"
                                >
                                    <ModalClose
                                        onClick={this.setModalOpenFalse.bind(this)}
                                    />
                                    <Box
                                        sx={{
                                            pr: 2,
                                        }}
                                    >
                                        <CommonForm
                                            fields={[
                                                {
                                                    name: 'name',
                                                    label: 'Полное название',
                                                    xs: 12,
                                                    sm: null,
                                                    md: null,
                                                    lg: null,
                                                    xl: null,
                                                    required: true,
                                                    default: null,
                                                    type: 'input',
                                                },
                                                {
                                                    type: 'submit',
                                                    label: 'Сохранить',
                                                    color: 'success',
                                                },
                                            ]}
                                            object={{
                                                name: this.state.name
                                            }}
                                            processForm={this.processForm.bind(this)}
                                        />
                                    </Box>
                                </ModalDialog>
                            </Modal>
                        </>:
                        null
                }
            </Stack>
        )
    }
}

export default OpportunityName;