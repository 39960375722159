import React from 'react';

import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import withNavigate from "../../utils/withNavigate";
import RouteTable from "./RouteTable";
import Sheet from "@mui/joy/Sheet";


class RouteWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            routes: null,
        };
    }

    async componentDidMount() {
        await this.retrieveRoutes();
    }

    async retrieveRoutes() {
        this.setState(
            {
                routes: null
            }
        );
        const url = 'route_state/my/';
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        await this.setState(
            {
                routes: response.data.results
            }
        );
    }

    render() {
        if (!this.state.routes) {
            return (<CircularProgress/>)
        }
        return (
            <Sheet
                style={
                    {
                        'maxHeight': 350,
                        'minHeight': 350,
                        'overflowY': 'scroll',
                    }
                }
            >
                <RouteTable
                    routes={this.state.routes}
                    callBack={this.retrieveRoutes.bind(this)}
                />
            </Sheet>
        )
    }
}

export default withNavigate(RouteWidget);
