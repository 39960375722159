import React from 'react';
import Link from '@mui/joy/Link';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress, Table} from "@mui/joy";
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import withNavigate from "../../utils/withNavigate";
import Pagination from '@mui/material/Pagination';
import withRouter from "../../utils/withRouter";
import {TableBody, TableCell, TableHead, TableRow} from "@mui/material";


class NotificationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notifications: [],
            pages: null,
        };
    }

    async componentDidMount() {
        await this.retrieveNotifications();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveNotifications();
        }
    }

    async retrieveNotifications() {
        const url = 'notification/';
        const api = new BaseApi();
        let params = {}
        if (this.props.params.pageNumber){
            params['page'] = this.props.params.pageNumber;
        }
        let response = await api.get(url, params);
        this.setState(
            {
                notifications: response.data.results,
                pages: response.data.total_pages,
            }
        );
    }

    render() {
        if (!(this.state.notifications && this.state.pages)) {
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1,
                    gap: 1,
                    flexWrap: 'wrap',
                    '& > *': {
                        minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                        flexGrow: 1,
                    },
                }}
            >
                <Typography level="h1" fontSize="xl4">
                    Уведомления
                </Typography>
                <Table
                    stickyHeader
                    hoverRow
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Создано
                            </TableCell>
                            <TableCell>
                                Название
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.notifications.map((row) => (
                            <TableRow
                                key={row.id}
                            >
                                <TableCell>
                                    <Typography
                                        sx={
                                            !row.is_read?
                                                {
                                                    fontWeight: 700,
                                                }:
                                                {}
                                        }
                                    >
                                        {row.created_on}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Link
                                        onClick={() => this.props.navigate(`/notification/detail/${row.id}`)}
                                        sx={
                                            !row.is_read?
                                                {
                                                    fontWeight: 700,
                                                }:
                                                {}
                                        }
                                    >
                                        {row.title}
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Pagination
                    count={this.state.pages}
                    page={this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1}
                    onChange={
                        (event, value) => {
                            this.props.navigate('/notification/page/'+value);
                        }
                    }
                />
            </Box>
        )
    }
}

export default withRouter(withNavigate(NotificationList));
