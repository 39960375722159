import React from 'react';

import Typography from "@mui/joy/Typography";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";
import {Slider, Stack} from "@mui/joy";
import BaseApi from "../../utils/BaseApi";
import Input from "@mui/joy/Input";
import {InnerInput} from "../Shared/FormFields";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import UserSubscriberContext from "../../context/UserSubscriberContext";


class TaskDuration extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            task: this.props.task
        };
    }

    async handleSlider(event, newValue){
        const api = new BaseApi();
        const url = `task/${this.props.task.id}/`;
        let response = await api.patch(
            url,
            {
                complete: newValue
            }
        );
        await this.setState(
            {
                task: response.data
            }
        );
        await this.props.callBack(response.data);
    }

    async handleDuration(event){
        const api = new BaseApi();
        const url = `task/${this.props.task.id}/`;
        let response = await api.patch(
            url,
            {
                duration_fact: event.target.value
            }
        );
        await this.setState(
            {
                task: response.data
            }
        );
        await this.props.callBack(response.data);
    }

    render() {
        return(
            <Card>
                <CardContent>
                    <Typography
                            level={'h4'}
                            sx={{
                                mb: 2
                            }}
                        >
                            Трудоемкость
                        </Typography>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <Input
                            slots={{ input: InnerInput }}
                            slotProps={{ input:
                                {
                                    label: 'Плановая трудоемкость',
                                    placeholder: '',
                                    type: 'text',
                                    required: false,
                                    name: 'duration_plan',
                                    defaultValue: this.state.task.duration_plan,
                                    disabled: true,
                                    color: 'success',
                                    // onChange: this.handleDuration.bind(this)
                                }
                            }}
                            sx={{
                                '--Input-minHeight': '56px',
                                '--Input-radius': '6px',
                            }}
                        />
                        <ArrowRightAltIcon />
                        <Input
                            slots={{ input: InnerInput }}
                            slotProps={{ input:
                                {
                                    label: 'Фактическая трудоемкость',
                                    placeholder: '',
                                    type: 'text',
                                    required: false,
                                    name: 'duration_fact',
                                    defaultValue: this.state.task.duration_fact,
                                    onBlur: this.handleDuration.bind(this),
                                    disabled: (
                                        ['COM', 'DEL', 'CLD', 'DCL'].includes(this.state.task.work_status) ||
                                        this.state.task.executor.id !== this.context.userObject.id
                                    ),
                                }
                            }}
                            sx={{
                                '--Input-minHeight': '56px',
                                '--Input-radius': '6px',
                            }}
                        />
                    </Stack>

                    <Typography
                        level={'body-md'}
                        sx={{mb: 2}}
                    >
                        <strong>Процент выполнения:</strong>
                    </Typography>
                    <Slider
                        disabled={
                            ['COM', 'DEL', 'CLD', 'DCL'].includes(this.state.task.work_status) ||
                            this.state.task.executor.id !== this.context.userObject.id
                        }
                        defaultValue={this.state.task.complete}
                        max={100}
                        valueLabelDisplay="on"
                        marks
                        step={1}
                        onChange={this.handleSlider.bind(this)}
                    />
                </CardContent>
            </Card>
        )
    }
}

export default TaskDuration