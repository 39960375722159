import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress, Modal, ModalClose, ModalDialog, Stack} from "@mui/joy";
import Box from "@mui/joy/Box";

// components
import EstimationPhaseCard from "./EstimationPhaseCard";

// icons
import IconButton from "@mui/joy/IconButton";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import EstimationPhaseCreate from "./EstimationPhaseCreate";
import Typography from "@mui/joy/Typography";


class EstimationRevisionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phases: null,
            modalOpen: false,
        };
    }

    async componentDidMount() {
        await this.retrievePhases();
    }

    async setModalOpenTrue(){
        await this.setState({
            modalOpen: true,
        })
        await this.retrievePhases();
    }

    setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        )
    }

    async retrievePhases() {
        this.setState(
            {
                phases: null,
            }
        );
        const url = `estimation_phase/`;
        const api = new BaseApi();
        const params = {
            revision_fk: this.props.revision.id
        };
        let response = await api.get(
            url,
            params
        );
        await this.setState(
            {
                phases: response.data.results
            }
        );
    }

    render() {
        if (!this.state.phases) {
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <IconButton
                    size={'sm'}
                    color="neutral"
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <WebAssetIcon />
                </IconButton>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                height: 'calc(80vh)',
                                pr: 2
                            }}
                        >
                            <Typography
                                level={'h4'}
                                sx={{
                                    mb: 2
                                }}
                            >
                                Ревизия №{this.props.revision.number}: {this.props.revision.desc}
                            </Typography>
                            <Stack>
                                {this.state.phases.map((each) => {
                                        return(
                                            <>
                                                <EstimationPhaseCreate
                                                    revisionId={this.props.revision.id}
                                                    opportunityId={this.props.revision.opportunity}
                                                    order={each.order}
                                                    callBack={this.retrievePhases.bind(this)}
                                                />
                                                <EstimationPhaseCard
                                                    phase={each}
                                                    callBack={this.retrievePhases.bind(this)}
                                                />
                                            </>
                                        )
                                    })
                                }
                            </Stack>
                            <EstimationPhaseCreate
                                revisionId={this.props.revision.id}
                                opportunityId={this.props.revision.opportunity}
                                order={this.state.phases.length ===0?1:(this.state.phases.slice(-1)[0].order+1)}
                                callBack={this.retrievePhases.bind(this)}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default EstimationRevisionModal