import React from "react";
import {CardOverflow, CircularProgress} from "@mui/joy";
import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import Link from "@mui/joy/Link";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";


// components
import BaseApi from "../../utils/BaseApi";
import withNavigate from "../../utils/withNavigate";


class ContactSearchCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: null,
        }
    }

    async retrieveContact() {
        const url = 'contact/' + this.props.contactId;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState({
            contact: response.data,
        });
    }

    async componentDidMount() {
        await this.retrieveContact();
    }

    render() {
        if (!this.state.contact) {
            return (<CircularProgress/>)
        }
        return (
            <Card
                orientation="horizontal"
                variant="outlined"
                sx={
                    {
                        'm': 2,
                    }
                }
            >
                <CardOverflow
                    variant='soft'
                    color={'primary'}
                    sx={{
                        px: 0.2,
                        writingMode: 'vertical-lr',
                        transform: 'rotate(180deg)',
                        textAlign: 'center',
                        fontSize: 'xs',
                        fontWeight: 'xl',
                        letterSpacing: '1px',
                        textTransform: 'uppercase',
                        borderLeft: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    Контакт
                </CardOverflow>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid xs={12} md={8} onClick={() => {this.props.callBack()}}>
                            <Typography level={'body2'} sx={{mb: 1}}>ФИО:</Typography>
                            <Link
                                onClick={() => this.props.navigate(`/contact/detail/${this.state.contact.id}`)}
                            >
                                {this.state.contact.last_name} {this.state.contact.first_name} {this.state.contact.patronymic}
                            </Link>
                        </Grid>
                        <Grid xs={12} md={4} onClick={() => {this.props.callBack()}}>
                            <Typography level={'body2'} sx={{mb: 1}}>Компания:</Typography>
                            <Link
                                onClick={() => this.props.navigate(`/account/detail/${this.state.contact.account.id}`)}
                            >
                                {this.state.contact.account.nameshort}
                            </Link>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }
}

export default withNavigate(ContactSearchCard)