import React from "react";

import {CircularProgress} from "@mui/joy";

// components
import BaseApi from "../../utils/BaseApi";
import TaskCard from "../Task/TaskCard";
import OpportunityCard from "../Opportunity/OpportunityCard";
import AccountCard from "../Account/AccountCard";
import ContactCard from "../Contact/ContactCard";
import DeliveryCard from "../Delivery/DeliveryCard";
import RouteCard from "../Route/RouteCard";


export function camelToSnakeCase(str){
    let s = str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    if (Array.from(s)[0] === '_'){
        s = s.substring(1);
    }
    return s
}

class NotificationLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            component: null,
        };
    }

    async componentDidMount() {
        await this.retrieveComponent(this.props.link);
    }

    async retrieveEntity(entity, id) {
        const url = `${camelToSnakeCase(entity)}/${id}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        return response.data
    }

    async retrieveComponent(link) {
        let component;
        switch (link.entity){
            case 'Task':
                component = <TaskCard
                    task={
                        await this.retrieveEntity(
                            link.entity,
                            link.entity_id
                        )
                    }
                />
                break;
            case 'Comment':
                let comment = await this.retrieveEntity(
                    link.entity,
                    link.entity_id
                )
                component = <NotificationLink
                    link={{
                        entity: comment.related_app,
                        entity_id: comment.related_app_id,

                    }}
                />
                break;
            case 'Opportunity':
                component = <OpportunityCard
                    opportunityID={link.entity_id}
                />
                break;
            case 'Account':
                let account = await this.retrieveEntity(
                    link.entity,
                    link.entity_id
                )
                component = <AccountCard
                    account={account}
                />
                break;
            case 'Contact':
                let contact = await this.retrieveEntity(
                    link.entity,
                    link.entity_id
                )
                component = <ContactCard
                    contact={contact}
                />
                break;
            case 'DeliveryState':
                let deliveryState = await this.retrieveEntity(
                    link.entity,
                    link.entity_id
                )
                component = <DeliveryCard
                    delivery={deliveryState.delivery}
                />
                break;
            case 'RouteState':
                let routeState = await this.retrieveEntity(
                    link.entity,
                    link.entity_id
                )
                component = <RouteCard
                    route={routeState.route}
                />
                break;
            default:
                break;
        }
        await this.setState(
            {
                component: component,
            }
        );
    }

    render() {
        if (!this.state.component) {
            return (<CircularProgress/>)
        }
        return(
            <>
                {this.state.component}
            </>
        )
    }
}

export default NotificationLink