import React from 'react';
import {Stack} from "@mui/joy";
import Typography from "@mui/joy/Typography";

// components
import OpportunityShortName from "./OpportunityShortName";
import OpportunityName from "./OpportunityName";
import OpportunitySource from "./OpportunitySource";
import OpportunityRevenueType from "./OpportunityRevenueType";
import Link from "@mui/joy/Link";
import withNavigate from "../../utils/withNavigate";

// icons
import LaunchIcon from '@mui/icons-material/Launch';


class OpportunityDetailHeader extends React.Component {
    render() {
        return(
            <Stack>
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <OpportunityShortName
                        shortName={this.props.opportunity.short_name}
                        opportunityId={this.props.opportunity.id}
                    />
                    <OpportunitySource
                        source={this.props.opportunity.source}
                        opportunityId={this.props.opportunity.id}
                    />
                    <OpportunityRevenueType
                        revenueType={this.props.opportunity.revenue_type}
                        opportunityId={this.props.opportunity.id}
                    />
                </Stack>
                <Stack>
                    <OpportunityName
                        name={this.props.opportunity.name}
                        opportunityId={this.props.opportunity.id}
                    />
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                >
                    <Typography level={'body-md'}>
                        <Link onClick={() => this.props.navigate(`/account/detail/${this.props.opportunity.account.id}`)}>
                            Компания: {this.props.opportunity.account.nameshort}
                        </Link>
                    </Typography>
                    <Link
                        level={'body-md'}
                        href={`https://meet.multiphysics.ru/${this.props.opportunity.uuid}`}
                        target={'_blank'}
                    >
                        Комната в Meet <LaunchIcon sx={{ml: 1}} />
                    </Link>
                </Stack>
            </Stack>
        )
    }
}

export default withNavigate(OpportunityDetailHeader);
