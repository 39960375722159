import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import Sheet from "@mui/joy/Sheet";

import TaskCard from "./TaskCard";


class TasksTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tasks: [],
        };
    }

    async componentDidMount() {
        await this.retrieveTasks();
    }

    async retrieveTasks() {
        const url = 'task/';
        const api = new BaseApi();
        let params = {};
        if ('opportunityId' in this.props){
            params.opportunity = this.props.opportunityId
        }
        if ('accountId' in this.props){
            params.account = this.props.accountId
        }
        let response = await api.get(
            url,
            params
        );
        this.setState(
            {
                tasks: response.data.results
            }
        );
    }

    render() {
        if (!this.state.tasks) {
            return (<CircularProgress/>)
        }
        return (
            <Sheet
                variant={'soft'}
            >
                {
                    this.state.tasks.map(
                        (task) => (
                            <TaskCard
                                task={task}
                                key={'task-card-'+task.id}
                                callBack={this.retrieveTasks.bind(this)}
                            />
                        )
                    )
                }
            </Sheet>
        )
    }
}

export default TasksTab;
