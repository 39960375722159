import React from 'react';

import BaseApi from "../../utils/BaseApi";
import CommonForm from "../Shared/CommonForm";
import {CircularProgress, Modal, ModalClose, ModalDialog} from "@mui/joy";
import Button from "@mui/joy/Button";
import Box from "@mui/joy/Box";

// icons
import EditIcon from '@mui/icons-material/Edit';


class QualificatorUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formFields: null,
            modalOpen: false,
            qualificator: {
                industry_focus: this.props.qualificator.industry_focus.map((each) => {return(each.id)}),
                physic_task_type: this.props.qualificator.physic_task_type.map((each) => {return(each.id)}),
                task_level: this.props.qualificator.task_level.id,
                calculations_purpose: this.props.qualificator.calculations_purpose.map((each) => {return(each.id)}),
                report_form: this.props.qualificator.report_form.map((each) => {return(each.id)}),
                extra_terms: this.props.qualificator.extra_terms.map((each) => {return(each.id)}),
            }
        };
    }

    async componentDidMount() {
        await this.retrieveFormFields();
    }

    async setModalOpenTrue(){
        await this.setState({
            modalOpen: true,
        })
    }

    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
        await this.props.callBack();
    }

    async retrieveFormFields() {
        const url = `qualificator/update_form/`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        await this.setState(
            {
                formFields: response.data
            }
        )
    }

    async processForm(form){
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        formDataObj.industry_focus = formDataObj.industry_focus.split(',');
        formDataObj.physic_task_type = formDataObj.physic_task_type.split(',');
        formDataObj.calculations_purpose = formDataObj.calculations_purpose.split(',');
        formDataObj.report_form = formDataObj.report_form.split(',');
        formDataObj.extra_terms = formDataObj.extra_terms.split(',');
        formDataObj.approved = false;
        let url = `qualificator/${this.props.qualificator.id}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            formDataObj
        );
        if (response.status === 200){
            await this.setModalOpenFalse();
        }
    }

    render() {
        if (!this.state.formFields) {
            return (<CircularProgress/>)
        }
        return(
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <Button
                    size={'sm'}
                    color="primary"
                    sx={{mt: 2}}
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <EditIcon sx={{mr: 1}}/> Редактировать
                </Button>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                height: 'calc(80vh)',
                                pr: 2
                            }}
                        >
                            <CommonForm
                                processForm={this.processForm.bind(this)}
                                fields={this.state.formFields}
                                object={this.state.qualificator}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>

        )
    }
}

export default QualificatorUpdate