import {createContext, useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import BaseApi from "../utils/BaseApi";

const AuthContext = createContext({});
export default AuthContext;


export const AuthProvider = ({children}) => {
    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens"))
            : null
    );
    let [loading, setLoading] = useState(true);
    const api = new BaseApi();
    const navigate = useNavigate();
    let loginUser = async (e) => {
        e.preventDefault();
        let response = await api.post(
            'auth/token/',
            {
                username: e.target.elements[0].value,
                password: e.target.elements[1].value,
            }
        )
        let data = await response.data;
        if (response.status === 200) {
            setAuthTokens(data);
            localStorage.setItem("authTokens", JSON.stringify(data));
            navigate("/");
        } else if (response.status === 500) {
            localStorage.removeItem('authTokens');
            console.log("Неполадки на сервере");
        } else if (response.status === 401) {
            localStorage.removeItem('authTokens');
            console.log("введен неккоректное имя пользователя или пароль");
        } else {
            localStorage.removeItem('authTokens');
            console.log(response.status);
        }
    };
    let logout = async (e) => {
        localStorage.removeItem('authTokens');
        navigate('/login');
    }
    const changePassword = (data) => {
        const api = new BaseApi();
        let response = api.post(
            'users/reset_password_confirm/',
            data
        )
        return response.data
    };
    let contextData = {
        loginUser: loginUser,
        changePassword: changePassword,
        logout: logout,
    };
    useEffect((e) => {
        if (!authTokens) {
            navigate('/login')
        }
        setLoading(false);
    }, [authTokens, loading]);
    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    );
};