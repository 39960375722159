import React from 'react';
import Card from "@mui/joy/Card";
import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import Chip from "@mui/material/Chip";
import BaseApi from "../../utils/BaseApi";
import CardContent from "@mui/joy/CardContent";
import {CardOverflow, CircularProgress, Stack} from "@mui/joy";
import Divider from "@mui/joy/Divider";
import Link from "@mui/joy/Link";
import Button from "@mui/joy/Button";

// componets
import TaskCreateModal from "../Task/TaskCreateModal";
import CommonForm from "../Shared/CommonForm";
import EstimationPhaseActive from "./EstimationPhaseActive";

// icons
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import TaskCard from "../Task/TaskCard";
import CloseIcon from '@mui/icons-material/Close';


class EstimationPhaseCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phase: this.props.phase,
            tasks: null,
            update: false,
            formFields: null,
            deleted: false,
        };
    }

    async componentDidMount() {
        await this.retrieveTasks();
    }

    async setUpdateTrue(){
        const url = `estimation_phase/update_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        let formFields = response.data
        await this.setState({
            formFields: formFields,
            update: true,
        })
    }

    setUpdateFalse(){
        this.setState({
            update: false,
        })
    }

    async processForm(form){
        this.setState(
            {
                phase: null,
            }
        );
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        if (!formDataObj.workers.includes('.')){
            formDataObj.workers = parseFloat(formDataObj.workers).toFixed(1)
        }
        const url = `estimation_phase/${this.props.phase.id}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            formDataObj
        );
        await this.setState(
            {
                phase: response.data,
                update: false,
            }
        )
    }

    async copy() {
        const url = `estimation_phase/${this.props.phase.id}/copy/`;
        const api = new BaseApi();
        await api.get(url, {});
        await this.props.callBack();
    }

    async destroy() {
        const url = `estimation_phase/${this.props.phase.id}/`;
        const api = new BaseApi();
        await api.delete(url, {});
        this.setState({
            deleted: true,
        })
        // await this.props.callBack();
    }

    async moveTop(){
        const url = `estimation_phase/${this.props.phase.id}/`;
        const api = new BaseApi();
        const data = {
            order: 1
        };
        await api.patch(
            url,
            data
        );
        await this.props.callBack();
    }

    async moveUp(){
        const url = `estimation_phase/${this.props.phase.id}/move_up/`;
        const api = new BaseApi();
        await api.get(url, {});
        await this.props.callBack();
    }

    async moveDown(){
        const url = `estimation_phase/${this.props.phase.id}/move_down/`;
        const api = new BaseApi();
        await api.get(url, {});
        await this.props.callBack();
    }

    async moveBottom(){
        const url = `estimation_phase/${this.props.phase.id}/move_bottom/`;
        const api = new BaseApi();
        await api.get(url, {});
        await this.props.callBack();
    }


    async retrieveTasks() {
        const url = `task/`;
        const api = new BaseApi();
        const params = {
            phase: this.props.phase.id
        };
        let response = await api.get(
            url,
            params,
        );
        await this.setState(
            {
                tasks: response.data.results
            }
        );
    }

    async retrievePhase() {
        const url = `estimation_phase/${this.props.phase.id}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                phase: response.data
            }
        );
    }
    
    render() {
        if (this.state.deleted){
            return (
                <Card
                    variant={'soft'}
                    sx={{
                        mb: 2,
                    }}
                >
                    <CardContent>
                        <Typography
                            color={'danger'}
                        >
                            <DeleteIcon sx={{mr: 1}}/>
                            Удалено
                        </Typography>
                    </CardContent>
                </Card>
            )
        }
        if (!this.state.phase) {
            return (<CircularProgress/>)
        }
        if (!this.state.tasks) {
            return (<CircularProgress/>)
        }
        return (
            <Card
                variant={'soft'}
                sx={{
                    mb: 2,
                }}
            >
                <CardContent>
                    {!this.state.update?
                        <>
                            <Grid container spacing={2}>
                                <Grid xs={12} md={3}>
                                    <EstimationPhaseActive
                                        phase={this.state.phase}
                                    />
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <Chip
                                        color={'primary'}
                                        size={'small'}
                                        label={this.state.phase.phase_type.title}
                                    />
                                </Grid>
                                <Grid xs={12} md={3}>
                                    <Chip
                                        color={'info'}
                                        size={'small'}
                                        label={this.state.phase.estimation_minimum}
                                        sx={{mr: 1}}
                                    />
                                    <Chip
                                        color={'info'}
                                        size={'small'}
                                        label={this.state.phase.estimation_normal}
                                        sx={{mr: 1}}
                                    />
                                    <Chip
                                        color={'info'}
                                        size={'small'}
                                        label={this.state.phase.estimation_maximum}
                                        sx={{mr: 1}}
                                    />
                                    <Chip
                                        color={'warning'}
                                        size={'small'}
                                        label={this.state.phase.real_workload}
                                        sx={{mr: 1}}
                                    />
                                </Grid>
                                <Grid xs={12} md={9}>
                                    <Typography level={'body1'}>
                                        {this.state.phase.title}
                                    </Typography>
                                    <Typography level={'body2'}>
                                        {this.state.phase.desc}
                                    </Typography>
                                </Grid>
                                <Grid xs={12} md={3}>
                                    <Chip
                                        color={'primary'}
                                        size={'small'}
                                        label={this.state.phase.workers + ' исп.'}
                                    />
                                </Grid>
                            </Grid>
                        </>:
                        <Stack>
                            <CommonForm
                                fields={this.state.formFields}
                                object={{
                                    title: this.state.phase.title,
                                    desc: this.state.phase.desc,
                                    phase_type: this.state.phase.phase_type.id,
                                    estimation_minimum: this.state.phase.estimation_minimum,
                                    estimation_normal: this.state.phase.estimation_normal,
                                    estimation_maximum: this.state.phase.estimation_maximum,
                                    workers: this.state.phase.workers,
                                    is_active: this.state.phase.is_active,
                                }}
                                processForm={this.processForm.bind(this)}
                            />
                            <Button
                                size={'sm'}
                                variant={'outlined'}
                                onClick={this.setUpdateFalse.bind(this)}
                                color={'warning'}
                            >
                                <CloseIcon sx={{mr: 1}}/> Отменить
                            </Button>
                        </Stack>
                    }
                </CardContent>
                <CardOverflow
                    // variant={"soft"}
                >
                    <Divider inset="context">
                        <Chip variant="soft" color="neutral" size="sm" label={'Привязанные задачи'} />
                    </Divider>
                    {this.state.tasks.map(each => {
                        return(
                            <TaskCard
                                task={each}
                                callBack={this.retrieveTasks.bind(this)}
                            />
                        )
                    })}
                    <TaskCreateModal
                        account={this.state.phase.opportunity.account.id}
                        opportunity={this.state.phase.opportunity.id}
                        phase={this.state.phase.id}
                        activeStep={3}
                        lockedSteps={[0, 1, 2]}
                        callBack={this.retrieveTasks.bind(this)}
                    />
                </CardOverflow>
                <CardOverflow>
                    <Divider inset="context"/>
                    <CardContent orientation="horizontal">
                        <Stack
                            direction="row"
                            spacing={1}
                        >
                            <Link
                                level="body-xs"
                                color={'primary'}
                                onClick={this.setUpdateTrue.bind(this)}
                            >
                                <EditIcon sx={{mr: 1}}/>
                                Редактировать
                            </Link>
                            <Link
                                level="body-xs"
                                color={'warning'}
                                onClick={this.copy.bind(this)}
                            >
                                <ContentCopyIcon sx={{mr: 1}}/>
                                Копировать
                            </Link>
                            <Link
                                level="body-xs"
                                color={'danger'}
                                onClick={this.destroy.bind(this)}
                            >
                                <DeleteIcon sx={{mr: 1}}/>
                                Удалить
                            </Link>
                        </Stack>
                        <Stack
                            direction={'row'}
                            spacing={1}
                            sx={{
                                marginLeft: 'auto'
                            }}
                        >
                            <KeyboardDoubleArrowUpIcon
                                sx={{cursor: 'pointer'}}
                                onClick={this.moveTop.bind(this)}
                            />
                            <KeyboardArrowUpIcon
                                sx={{cursor: 'pointer'}}
                                onClick={this.moveUp.bind(this)}
                            />
                            <KeyboardArrowDownIcon
                                sx={{cursor: 'pointer'}}
                                onClick={this.moveDown.bind(this)}
                            />
                            <KeyboardDoubleArrowDownIcon
                                sx={{cursor: 'pointer'}}
                                onClick={this.moveBottom.bind(this)}
                            />
                       </Stack>
                    </CardContent>
                </CardOverflow>
            </Card>
        )
    }
}

export default EstimationPhaseCard