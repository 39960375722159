import React from "react";
import Typography from "@mui/joy/Typography";
import Pagination from "@mui/material/Pagination";
import {Stack} from "@mui/joy";
import withNavigate from "../../utils/withNavigate";
import CommonFilterModal from "./CommonFilterModal";


class CommonPaginatedComponentList extends React.Component{
    render() {
        return (
            <Stack
                direction="column"
                spacing={2}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography level="h1" fontSize="xl4">
                        {this.props.title}
                    </Typography>
                    {
                        this.props.filterFields?
                            <CommonFilterModal
                                callBack={this.props.filterCallBack}
                                filterFields={this.props.filterFields}
                            />:
                            null
                    }
                </Stack>
                <Stack>
                    {this.props.data.map((each) => {
                            let props = {};
                            props[`${this.props.componentKeyword}`] = each
                            return (
                                <this.props.component
                                    {...props}
                                />
                            )
                    })}
                </Stack>
                <Pagination
                    count={this.props.pages}
                    page={this.props.pageNumber}
                    onChange={
                        (event, value) => {
                            this.props.navigate(`/${this.props.url}/page/`+value);
                        }
                    }
                />
            </Stack>
        )
    }
}

export default withNavigate(CommonPaginatedComponentList)