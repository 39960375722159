import React from 'react';
import {CircularProgress} from "@mui/joy";
import Typography from "@mui/joy/Typography";

// components
import BaseApi from "../../utils/BaseApi";
import KpiSubscriberList from "./KpiSubscriberList";
import withNavigate from "../../utils/withNavigate";

// icons
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


class KpiSubordinateList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subordinates: null,
        };
    }

    async componentDidMount() {
        await this.retrieveSubordinates();
    }

    async retrieveSubordinates() {
        const url = 'subscriber/descendants/';
        const api = new BaseApi();
        let response = await api.get(
            url,{}
        );
        this.setState({
            subordinates: response.data.results
        })
    }

    render() {
        if (!this.state.subordinates) {
            return (<CircularProgress/>)
        }
        return (
            <>
                <Typography
                    level={'h1'}
                >
                    КПЭ коллег
                </Typography>
                {this.state.subordinates.map((subordinate) => {
                    return(
                        <>
                            <Typography
                                level={'h2'}
                            >
                                {subordinate.user.last_name} {subordinate.user.first_name}
                                <CalendarMonthIcon onClick={() => {this.props.navigate(`/task/calendar/${subordinate.id}`)}}/>
                            </Typography>
                            <KpiSubscriberList
                                subscriber={subordinate}
                            />
                        </>
                    )
                })}

            </>
        )
    }
}

export default withNavigate(KpiSubordinateList)