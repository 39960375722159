import React from "react";
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import CommonDetail from "../Shared/CommonDetail";
import withRouter from "../../utils/withRouter";

// Tabs
import OpportunityDetailHeader from "./OpportunityDetailHeader";
import DeliveryTab from "../Delivery/DeliveryTab";
import TeamTab from "./TeamTab";
import ContactTeamTab from "./ContactTeamTab";
import AttachmentsTab from "../Attachment/AttachmentsTab";
import TasksTab from "../Task/TasksTab";
import TasksCalendarTab from "../Task/TasksCalendarTab"
import EstimationTab from "../Estimation/EstimationTab";
import QualificatorTab from "../Qualificator/QualificatorTab";
import EvaluationTab from "./EvaluationTab"
import ContractTab from "./ContractTab";

// icons
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import AssignmentIcon from '@mui/icons-material/Assignment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScaleIcon from '@mui/icons-material/Scale';
import GradeIcon from '@mui/icons-material/Grade';
import GavelIcon from '@mui/icons-material/Gavel';



class OpportunityDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opportunity: null,
        };
    }

    async componentDidMount() {
        await this.retrieveOpportunity();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveOpportunity();
        }
    }

    async retrieveOpportunity() {
        await this.setState(
            {
                opportunity: null
            }
        );
        const url = `opportunity/${this.props.params.opportunityId}`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        await this.setState(
            {
                opportunity: response.data
            }
        );
    }

    render() {
        if (!this.state.opportunity) {
            return (<CircularProgress/>)
        }
        return (
            <CommonDetail
                appName={'Opportunity'}
                appId={this.state.opportunity.id}
                headerComponent={<OpportunityDetailHeader opportunity={this.state.opportunity} />}
                defaultTabValue={'delivery'}
                headerOverflowTitle={this.state.opportunity.status_human}
                headerOverflowColor={this.state.opportunity.status_color}
                tabComponents={[
                    {
                        title: 'Бизнес-процессы',
                        value: 'delivery',
                        icon: <AccountTreeIcon />,
                        component: <DeliveryTab
                            opportunityId={this.state.opportunity.id}
                        />,
                    },
                    {
                        title: 'Команда',
                        value: 'team',
                        icon: <GroupsIcon />,
                        component: <TeamTab
                            opportunityId={this.state.opportunity.id}
                        />,
                    },
                    {
                        title: 'Команда заказчика',
                        value: 'contact-team',
                        icon: <PeopleOutlineIcon />,
                        component: <ContactTeamTab
                            opportunity={this.state.opportunity}
                        />,
                    },
                    {
                        title: 'Вложения',
                        value: 'attachment',
                        icon: <AttachFileIcon />,
                        component: <AttachmentsTab
                            appId={this.state.opportunity.id}
                            appName={'Opportunity'}
                        />
                    },
                    {
                        title: 'Квалификатор',
                        value: 'qualificator',
                        icon: <ScaleIcon />,
                        component: <QualificatorTab
                            qualificatorId={this.state.opportunity.qualificator}
                            opportunity={this.state.opportunity}
                        />,
                    },
                    {
                        title: 'Трудоемкость',
                        value: 'estimation',
                        icon: <LocalPizzaIcon />,
                        component: <EstimationTab
                            opportunityId={this.state.opportunity.id}
                        />,
                    },
                    {
                        title: 'Задачи',
                        value: 'task',
                        icon: <AssignmentIcon />,
                        component: <TasksTab
                            opportunityId={this.state.opportunity.id}
                        />,
                    },
                    {
                        title: 'Календарь',
                        value: 'calendar',
                        icon: <CalendarMonthIcon />,
                        component: <TasksCalendarTab
                            opportunityId={this.state.opportunity.id}
                        />,
                    },
                    {
                        title: 'Оценка проекта',
                        value: 'evaluation',
                        icon: <GradeIcon />,
                        component: <EvaluationTab
                            opportunityId={this.state.opportunity.id}
                        />,
                    },
                    {
                        title: 'Договор',
                        value: 'contract',
                        icon: <GavelIcon />,
                        component: <ContractTab
                            opportunityId={this.state.opportunity.id}
                        />,
                    },
                ]}
            />
        )
    }
}

export default withRouter(OpportunityDetail);
