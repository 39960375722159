import React from 'react';

import Avatar from "@mui/joy/Avatar";
import {CardOverflow, Stack} from "@mui/joy";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import Link from "@mui/joy/Link";


// components
import EstimationRevisionModal from "./EstimationRevisionModal";
import EstimationRevisionChart from "./EstimationRevisionChart";
import EstimationRevisionUpdate from "./EstimationRevisionUpdate";
import EstimationRevisionSetCurrent from "./EstimationRevisionSetCurrent";
import BaseApi from "../../utils/BaseApi";
import retrievePermission from "../../utils/retrievePermission";
import UserSubscriberContext from "../../context/UserSubscriberContext";

// icons
import BoltIcon from '@mui/icons-material/Bolt'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";



class EstimationRevisionCard extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            copyPermission: 0,
            updatePermission: 0
        };
    }

    async componentDidMount() {
        await this.retrieveCopyPermission();
        await this.retrieveUpdatePermission();
    }

    async retrieveCopyPermission() {
        let p = await retrievePermission(
            'EstimationRevisionViewSet',
            'copy',
            this.context.subscriberObject
        );
        await this.setState(
            {
                copyPermission: p
            }
        );
    }

    async retrieveUpdatePermission() {
        let p = await retrievePermission(
            'EstimationRevisionViewSet',
            'update',
            this.context.subscriberObject
        );
        await this.setState(
            {
                updatePermission: p
            }
        );
    }

    async copyRevision() {
        const url = `estimation_revision/${this.props.revision.id}/copy`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        await this.props.callBack();
    }

    render() {
        return(
            <Card>
                <CardContent>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                        >
                            <Avatar color={this.props.revision.current?'warning':'primary'}>
                                {
                                    this.props.revision.current?
                                        <BoltIcon />:
                                        <HourglassEmptyIcon />
                                }
                            </Avatar>
                            <Typography level="title-md">
                                Ревизия №{this.props.revision.number}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                        >
                            <EstimationRevisionSetCurrent
                                revision={this.props.revision}
                                callBack={this.props.callBack}
                            />
                            <EstimationRevisionModal
                                revision={this.props.revision}
                            />
                        </Stack>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Typography level="body-md">
                            {this.props.revision.desc}
                        </Typography>
                    </Stack>
                    <EstimationRevisionChart
                        revision={this.props.revision}
                    />
                </CardContent>
                <CardOverflow>
                    <Divider inset="context"/>
                    <CardContent orientation="horizontal">
                        <Stack
                            direction="row"
                            spacing={1}
                        >
                            {
                                this.state.updatePermission > 0?
                                    <EstimationRevisionUpdate
                                        callBack={this.props.callBack}
                                        revision={this.props.revision}
                                    />:
                                    null
                            }

                            {
                                this.state.copyPermission > 0?
                                    <Link
                                        level="body-xs"
                                        color={'warning'}
                                        onClick={this.copyRevision.bind(this)}
                                    >
                                        <ContentCopyIcon sx={{mr: 1}}/>
                                        Копировать
                                    </Link>:
                                    null
                            }
                        </Stack>
                    </CardContent>
                </CardOverflow>
            </Card>

        )
    }
}

export default EstimationRevisionCard