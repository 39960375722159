import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import PermissionViewSet from "./PermissionViewSet";



class PermissionReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            report: null,
        };
    }

    async componentDidMount() {
        await this.retrieveReport();
    }

    async retrieveReport() {
        this.setState(
            {
                phases: null,
            }
        );
        const url = `api_permission/report`;
        const api = new BaseApi();
        let response = await api.get(
            url, {}
        );
        await this.setState(
            {
                report: response.data
            }
        );
    }

    render(){
        if (!this.state.report){
            return (<CircularProgress />)
        }
        return(
            <>
                <Typography level={'h2'}>
                    Права API
                </Typography>
                {
                    this.state.report.map(each => {
                        return(
                            <PermissionViewSet
                                viewset={each}
                            />
                        )
                    })
                }
            </>
        )
    }
}

export default PermissionReport