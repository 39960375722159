import React from 'react';
import CommonSidebar from "../Shared/CommonSidebar";

//icons
import ListIcon from '@mui/icons-material/List';


class OperationsSidebar extends React.Component {
    render() {
        return(
            <CommonSidebar
                groups={[
                    {
                        title: 'Операционное управление',
                        items: [
                            {
                                title: 'Премии коллег',
                                link: '/operations/bonus/',
                                icon: <ListIcon />,
                            },
                            {
                                title: 'Отпуска коллег',
                                link: '/operations/vacation/',
                                icon: <ListIcon />,
                            },
                        ],
                    }
                    ]
                }
            />
        )
    }
}

export default OperationsSidebar;
