import React from "react";
import Typography from "@mui/joy/Typography";
import {Stack} from "@mui/joy";


class LeadDetailHeader extends React.Component {
    render() {
        return(
            <Stack>
                <Typography level={'h4'}>
                    {this.props.lead.title}
                </Typography>
            </Stack>
        )
    }
}

export default LeadDetailHeader