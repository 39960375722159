import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import CardContent from '@mui/joy/CardContent';
import Divider from '@mui/joy/Divider';
import DeliveryWidget from "../Delivery/DeliveryWidget";
import TaskWidget from "../Task/TaskWidget";
import withNavigate from "../../utils/withNavigate";
import Grid from "@mui/joy/Grid";
import {CardActions} from "@mui/material";
import Box from "@mui/joy/Box";
import {CircularProgress} from "@mui/joy";

// components
import BaseApi from "../../utils/BaseApi";
import HomePageItemCreate from "./HomePageItemCreate";

// icons
import LaunchIcon from '@mui/icons-material/Launch';
import IconButton from "@mui/joy/IconButton";
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import CloseIcon from '@mui/icons-material/Close';
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssignmentIcon from "@mui/icons-material/Assignment";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import NoteWidget from "../Note/NoteWidget";
import RouteWidget from "../Route/RouteWidget";


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};


class HomePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            renderList: [],
            subscriberId: null,
        };
        this.modules = {
            'delivery-module': {
                title: 'Мои бизнес-процессы',
                widget: DeliveryWidget,
                link: '/delivery/',
                icon: AccountTreeIcon
            },
            'taskforme-module': {
                title: 'Мои задачи',
                widget: TaskWidget,
                link: '/task/',
                icon: AssignmentIcon
            },
            'note-module': {
                title: 'Мои заметки',
                widget: NoteWidget,
                // link: '/task/',
                icon: AssignmentIcon
            },
            'route-module': {
                title: 'Мои маршруты',
                widget: RouteWidget,
                link: '/route/',
                icon: AccountTreeIcon
            },
        };
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    constructRenderList(items) {
        let renderList = [];
        for (let i = 0; i < items.length; i++) {
            let elem = items[i]
            renderList.push(
                {
                    id: elem.id,
                    title: this.modules[elem.id].title,
                    widget: this.modules[elem.id].widget,
                    link: this.modules[elem.id].link,
                    icon: this.modules[elem.id].icon,
                    size: elem.size * 6,
                }
            )
        }
        return renderList
    }

    async retrieveItems(){
        const url = 'subscriber/me';
        const api = new BaseApi();
        let response = await api.get(url, {});
        let items = []
        if (response.data.homepage_config !== null){
            items = response.data.homepage_config;
        }
        await this.setState(
            {
                subscriberId: response.data.id,
                items: items,
                renderList: this.constructRenderList(items)
            }
        );
    }

    async componentDidMount() {
        await this.retrieveItems();
    }

    async onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );
        this.setState({
            items: items,
            renderList: this.constructRenderList(items),
        });
        await this.patchItems(items);

    }

    async setItemFull(id) {
        let newItems = [];
        for (let i = 0; i < this.state.items.length; i++) {
            let item = this.state.items[i]
            if (item.id === id){
                newItems.push(
                    {
                        id: item.id,
                        size: 2,
                    }
                )
            }
            else {
                newItems.push(
                    item
                )
            }
        }
        await this.patchItems(newItems);
        this.setState({
            items: newItems,
            renderList: this.constructRenderList(newItems),
        });
    }

    async setItemHalf(id) {
        let newItems = [];
        for (let i = 0; i < this.state.items.length; i++) {
            let item = this.state.items[i]
            if (item.id === id){
                newItems.push(
                    {
                        id: item.id,
                        size: 1,
                    }
                )
            }
            else {
                newItems.push(
                    item
                )
            }
        }
        await this.patchItems(newItems);
        await this.setState({
            items: newItems,
            renderList: this.constructRenderList(newItems),
        });
    }

    async removeItem(id){
        let newItems = [];
        for (let i = 0; i < this.state.items.length; i++) {
            let item = this.state.items[i]
            if (item.id !== id){
                newItems.push(
                    item
                )
            }
        }
        await this.patchItems(newItems);
        await this.setState({
            items: newItems,
            renderList: this.constructRenderList(newItems),
        });
    }

    async patchItems(items){
        const url = `subscriber/${this.state.subscriberId}/`;
        const api = new BaseApi();
        const data = {
            homepage_config: items,
        }
        await api.patch(
            url,
            data
        );
    }

    async addItem(item){
        let items = this.state.items;
        items.push(item);
        await this.patchItems(items);
        await this.setState({
            items: items,
            renderList: this.constructRenderList(items),
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        if (!this.state.subscriberId) {
            return (<CircularProgress/>)
        }
        return(
            <>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <Grid
                                container
                                spacing={2}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {this.state.renderList.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <Grid
                                                md={item.size}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                            >
                                                <Card>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Typography level="title-md" startDecorator={<item.icon />}>
                                                            {item.title}
                                                            <IconButton
                                                                aria-label="delivery-link"
                                                                size={'sm'}
                                                                color="neutral"
                                                                onClick={() => this.props.navigate(item.link)}
                                                                sx={{'ml': 3,}}
                                                            >
                                                                <LaunchIcon />
                                                            </IconButton>
                                                        </Typography>
                                                        <CardActions buttonflex="0 1 120px">
                                                            <IconButton
                                                                size={'sm'}
                                                                color="neutral"
                                                                sx={{'ml': 1,}}
                                                            >
                                                                {
                                                                    item.size===6?
                                                                        <OpenInFullIcon onClick={this.setItemFull.bind(this, item.id)}/>:
                                                                        <CloseFullscreenIcon onClick={this.setItemHalf.bind(this, item.id)}/>
                                                                }
                                                            </IconButton>
                                                            <IconButton
                                                                size={'sm'}
                                                                color="neutral"
                                                                sx={{'ml': 3,}}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <ControlCameraIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                size={'sm'}
                                                                color="neutral"
                                                                sx={{'ml': 1,}}
                                                                onClick={this.removeItem.bind(this, item.id)}
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </CardActions>
                                                    </Box>
                                                    <Divider inset="none" sx={{ mt: 1, mb: 1 }} />
                                                    <CardContent>
                                                        <item.widget />
                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>
                <HomePageItemCreate
                    callBack={this.addItem.bind(this)}
                    modules={this.modules}
                />
            </>
        )
    }
}

export default withNavigate(HomePage);
