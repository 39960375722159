import React from "react";
import Typography from "@mui/joy/Typography";
import IconButton from "@mui/joy/IconButton";
import Box from "@mui/joy/Box";

// icons
import LogoutIcon from "@mui/icons-material/Logout";

import withNavigate from "../../utils/withNavigate";
import UserSubscriberContext from "../../context/UserSubscriberContext";


class ProfileMini extends React.Component {
    static contextType = UserSubscriberContext

    async logout(){
        localStorage.removeItem('authTokens');
        this.props.navigate('/login');
    }

    render() {

        return(
            <Box sx={{ pl: 1, mt: 'auto', display: 'flex', alignItems: 'center' }}>
                <div>
                    <Typography fontWeight="lg" level="body2">
                        {this.context.userObject.first_name} {this.context.userObject.last_name}
                    </Typography>
                    <Typography level="body2">@{this.context.userObject.username}</Typography>
                </div>
                <IconButton variant="plain" sx={{ ml: 'auto' }} onClick={this.logout.bind(this)}>
                    <LogoutIcon />
                </IconButton>
            </Box>
        )
    }
}

export default withNavigate(ProfileMini);
