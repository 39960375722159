import React from 'react';

import Chip from "@mui/material/Chip";
import Box from "@mui/joy/Box";
import {CircularProgress, Modal, ModalClose, ModalDialog} from "@mui/joy";
import BaseApi from "../../utils/BaseApi";
import Button from "@mui/joy/Button";
import CommonForm from "../Shared/CommonForm";

// icons
import TodayIcon from '@mui/icons-material/Today';


class TaskDetailChangeEndDate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statuses: null,
            modalOpen: false,
        }
    }

    async setModalOpenTrue(){
        await this.setState({
            modalOpen: true,
        })
    }

    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
        await this.props.callBack();
    }

    async processForm(form){
        const url = `task/${this.props.task.id}/`;
        const api = new BaseApi();
        let response = await api.patch(
            url,
            form.state.formData
        );
        if (response.status === 200){
            await this.setModalOpenFalse();
        }
    }

    render() {
        return(
            <Box
                sx={{
                    display: 'inline'
                }}
            >
                <Button
                    size={'sm'}
                    color="success"
                    sx={{mt: 0, mb: 1}}
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <TodayIcon sx={{mr: 1}}/> Сменить дату окончания
                </Button>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                pr: 2
                            }}
                        >
                            <CommonForm
                                key={'step-2-form'}
                                fields={
                                    [
                                        {
                                            'name': 'end_dt',
                                            'label': 'Новая дата окончания',
                                            'xs': 12,
                                            'sm': null,
                                            'md': null,
                                            'lg': null,
                                            'xl': null,
                                            'required': true,
                                            'type': 'date',
                                            'default': this.props.task
                                        },
                                        {
                                            type: 'submit',
                                            label: 'Сохранить'
                                        }
                                    ]
                                }
                                processForm={this.processForm.bind(this)}
                            />
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default TaskDetailChangeEndDate