import React from 'react';
import Card from "@mui/joy/Card";
import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import Chip from "@mui/material/Chip";
import {CardOverflow, Stack} from "@mui/joy";
import Divider from "@mui/joy/Divider";

// components
import DeliveryStateConditionsTable from "./DeliveryStateConditionsTable";
import AttachmentListItem from "../Attachment/AttachmentListItem";
import ProfileHorizontalByProps from "../Profile/ProfileHorizontalByProps";


class DeliveryStateCard extends React.Component {
    render() {
        return (
            <Card
                variant={'plain'}
                sx={{
                    mb: 2,
                }}
            >
                <CardOverflow
                    variant={'soft'}
                    color={this.props.state.stage.color}
                >
                    <Typography
                        level={'body-md'}
                        sx={{
                            alignSelf: 'center',
                            p: 1,
                        }}
                    >
                        {this.props.state.stage.title}
                    </Typography>
                </CardOverflow>
                <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                        <Typography level={'body2'} sx={{mb: 1}}>Исполнитель:</Typography>
                        <ProfileHorizontalByProps
                            user={this.props.state.operator.user}
                        />
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Typography level={'body2'} sx={{mb: 1}}>Дней в работе:</Typography>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                        >
                            <Chip
                                color={this.props.state.delay[1]?'success':'error'}
                                size={'small'}
                                label={this.props.state.delay[0]}
                            />
                            <Typography level={'body2'} sx={{mb: 1}}>из</Typography>
                            <Chip
                                color={'primary'}
                                size={'small'}
                                label={this.props.state.stage.end_delay}
                            />
                        </Stack>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Typography level={'body2'} sx={{mb: 1}}>Создана:</Typography>
                        <Typography level={'body1'}>
                            {this.props.state.created_on}
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Typography level={'body2'} sx={{mb: 1}}>Пройдена:</Typography>
                        <Typography level={'body1'}>
                            {this.props.state.passed_by?this.props.state.updated_on:'-'}
                        </Typography>
                    </Grid>

                    <Grid xs={12} md={12}>
                        <Typography level={'body2'} sx={{mb: 1}}>Вложение:</Typography>
                        {this.props.state.attachment?
                            <Card
                                variant={'soft'}
                            >
                                <AttachmentListItem
                                    attachment={this.props.state.attachment}
                                />
                            </Card>
                            :
                            '-'
                        }
                    </Grid>
                    <Grid xs={12} md={12}>
                        <Typography level={'body2'} sx={{mb: 1}}>Комментарий:</Typography>
                        <Typography level={'body1'} sx={{mb: 1}}>
                            {this.props.state.note?this.props.state.note:'-'}
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={12}>
                        <Divider>
                            <Chip variant="soft" color="neutral" size="sm" label={'Условия стадии'} />
                        </Divider>
                        <DeliveryStateConditionsTable deliveryStateId={this.props.state.id}/>
                    </Grid>
                </Grid>
            </Card>
        )
    }
}

export default DeliveryStateCard