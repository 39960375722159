import React from 'react';
import RenderIfVisible from 'react-render-if-visible'

import {CircularProgress, Stack} from "@mui/joy";

// components
import RouteCard from "./RouteCard";
import BaseApi from "../../utils/BaseApi";


class RouteTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            routes: null,
        };
    }

    async componentDidMount() {
        await this.retrieveRoutes();
    }

    async retrieveRoutes() {
        const url = `route/`;
        const api = new BaseApi();
        const params = {
            entity: this.props.entity,
            entity_id: this.props.entity_id
        };
        let response = await api.get(url, params);
        this.setState(
            {
                routes: response.data.results,
            }
        );
    }

    render() {
        if (!this.state.routes) {
            return (<CircularProgress/>)
        }
        return(
            <Stack spacing={2}>
                {this.state.routes.map(
                    each => {
                        return (
                            <RenderIfVisible stayRendered={true} key={'route-card-' + each}>
                                <RouteCard
                                    route={each}
                                    callBack={this.retrieveRoutes.bind(this)}
                                />
                            </RenderIfVisible>
                        )
                    })
                }
            </Stack>
        )
    }
}

export default RouteTab;