import React from "react";
import Input from "@mui/joy/Input";
import {CircularProgress, FormHelperText, Radio, RadioGroup, Textarea} from "@mui/joy";
import {TextField, } from "@mui/material"
import Grid from "@mui/joy/Grid";
import FormControl from "@mui/joy/FormControl";
import Button from "@mui/joy/Button";
import {DatePicker, DateTimePicker} from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import {
    CustomDateWrapperField,
    CustomMultiSelectField,
    CustomSelectField,
    CustomSwitch,
    InnerInput
} from "./FormFields";


class CommonForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'errors': {},
            'processing': false,
            'prev_values': null,
        }
    }

    generate_input(field) {
        let value = null;
        if (this.state.prev_values !== null){
            value = this.state.prev_values.get(field.name);
        }
        else if (this.props.object){
            value = this.props.object[field.name];
        }
        else if (field.default !== null) {
            value = field.default;
        }
        let label = field.label;
        // if (field.required){
        //     label += ' *';
        // }
        switch (field.type) {
            case 'submit':
                return(
                    <Button type={'submit'} color={field.color}>
                        {field.label}
                    </Button>
                )
            case 'select':
                return(
                    <CustomSelectField
                        defaultValue={value}
                        options={field.options}
                        label={label}
                        required={field.required}
                        name={field.name}
                        empty={field.empty}
                        emptyLabel={field.emptyLabel}
                    />
                )
            case 'radio':
                return(
                    <RadioGroup
                        defaultValue={parseInt(value)}
                        name={field.name}
                        required={field.required}
                    >
                        {field.options.map(each => {
                            return(
                                <Radio value={each.value} label={each.label} variant="outlined" />
                            )
                        })}
                    </RadioGroup>
                )
            case 'multiselect':
                return(
                    <CustomMultiSelectField
                        defaultValue={value}
                        options={field.options}
                        label={label}
                        required={field.required}
                        name={field.name}
                    />
                )
            case 'date':
                return(
                    <CustomDateWrapperField
                        component={DatePicker}
                        label={label}
                        required={field.required}
                        defaultValue={dayjs(value)}
                        format={'YYYY-MM-DD'}
                        name={field.name}
                    />
                )
            case 'datetime':
                return(
                    <CustomDateWrapperField
                        component={DateTimePicker}
                        label={label}
                        required={field.required}
                        defaultValue={dayjs(value)}
                        format={'YYYY-MM-DD'}
                        name={field.name}
                    />
                )
            case 'checkbox':
                return(
                    <CustomSwitch
                        required={field.required}
                        name={field.name}
                        value={value}
                        label={label}
                    />
                )
            case 'number':
                return(
                    <Input
                        slots={{ input: InnerInput }}
                        slotProps={{ input:
                            {
                                label: label,
                                placeholder: '',
                                type: 'number',
                                required: field.required,
                                name: field.name,
                                defaultValue: value,
                            }
                        }}
                        sx={{
                            '--Input-minHeight': '56px',
                            '--Input-radius': '6px',
                        }}
                    />
                )
            case 'textarea':
                return (
                    <Textarea
                        placeholder={label}
                        minRows={5}
                        required={field.required}
                        name={field.name}
                        defaultValue={value}
                    />
                )
            case 'input':
                return(
                    <Input
                        slots={{ input: InnerInput }}
                        slotProps={{ input:
                            {
                                label: label,
                                placeholder: '',
                                type: 'text',
                                required: field.required,
                                name: field.name,
                                defaultValue: value,
                            }
                        }}
                        sx={{
                            '--Input-minHeight': '56px',
                            '--Input-radius': '6px',
                        }}
                    />
                )
            case 'hidden':
                return ('')
            case 'file':
                return (
                    <TextField
                        sx={{
                            '--Input-minHeight': '56px',
                            '--Input-radius': '6px',
                        }}
                        type={'file'}
                        // label={label}
                        required={field.required}
                        name={field.name}
                        defaultValue={value}
                        id={'form-'+field.name}
                        onChange={(event) => {
                            if(event.target.files[0].size > 5242880) {
                                alert("Размер файла должен быть менее 5Мб");
                                event.target.value = "";
                            }
                        }}
                    />
                )
            default:
                console.log(`Type of field ${field.type} is not supported.`);
            }
    }

    async processForm(event){
        event.preventDefault();
        await this.setState({
            formData: new FormData(event.currentTarget),
            processing: true,
        });
        await this.props.processForm(this);
        await this.setState({
            processing: false,
        });
    }

    render() {
        return(
            <form
                onSubmit={this.processForm.bind(this)}
            >
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    {this.props.fields.map((field) => (
                        <Grid xs={field.xs} sm={field.sm} md={field.md} lg={field.lg} xl={field.xl} key={'grid-'+field.name}>
                            <FormControl error={!!this.state.errors[field.name]}>
                                {this.generate_input(field)}
                                <FormHelperText>
                                    {this.state.errors[field.name]}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    ))
                    }
                </Grid>
                {this.state.processing?<CircularProgress/>:<></>}
            </form>
        )
    }
}

export default CommonForm;