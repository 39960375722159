import React from 'react';
import {Stack} from "@mui/joy";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";

// components
import TaskDetailNextStatus from "./TaskDetailNextStatus";
import TaskDuration from "./TaskDuration";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import TaskDetailDelegate from "./TaskDetailDelagate";
import TaskDetailChangeEndDate from "./TaskDetailChangeEndDate";


class TaskTab extends React.Component {
    static contextType = UserSubscriberContext
    render() {
        return(
            <Stack
                spacing={2}
            >
                <Stack
                    spacing={2}
                    direction={'row'}
                >
                    {
                        (
                            !['COM', 'DEL', 'CLD', 'DCL'].includes(this.props.task.work_status) && (
                                (this.props.task.executor.id === this.context.userObject.id && ['WRK', 'PSD'].includes(this.props.task.work_status)) ||
                                (this.props.task.owner.id === this.context.userObject.id && ['OAC',].includes(this.props.task.work_status))
                            )
                        )?
                            <TaskDetailNextStatus
                                taskId={this.props.task.id}
                                callBack={this.props.callBack}
                            />:
                            null
                    }
                    {
                        ['WRK',].includes(this.props.task.work_status) ?
                            <TaskDetailDelegate
                                taskId={this.props.task.id}
                                callBack={this.props.callBack}
                            /> :
                            null
                    }
                    {
                        ['WRK', ].includes(this.props.task.work_status)?
                            <TaskDetailChangeEndDate
                                task={this.props.task}
                                callBack={this.props.callBack}
                            />:
                            null
                    }
                </Stack>
                <Card>
                    <CardContent>
                        <Typography
                            level={'h4'}
                        >
                            Описание
                        </Typography>
                        <div dangerouslySetInnerHTML={{__html: this.props.task.desc}} />
                    </CardContent>
                </Card>
                <TaskDuration
                    task={this.props.task}
                    callBack={this.props.callBack}
                />
            </Stack>
        )
    }
}

export default TaskTab