import React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import {Modal, ModalClose, ModalDialog} from "@mui/joy";
import Typography from "@mui/joy/Typography";

// components
import CommonForm from "../Shared/CommonForm";
import BaseApi from "../../utils/BaseApi";

// icons
import AddCircleIcon from "@mui/icons-material/AddCircle";


class RequisitesCreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            formFields: null,
        };
    }

    async retrieveFields(){
        const url = `requisites/create_form/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        return(response.data)
    }

    async setModalOpenTrue(){
        let formFields = await this.retrieveFields();
        await this.setState({
            modalOpen: true,
            formFields: formFields,
        })
    }

    async processForm(form) {
        let formDataObj = Object.fromEntries(
            form.state.formData.entries()
        );
        formDataObj.account = this.props.account.id;
        const url = `requisites/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj
        );
        if (response.status === 201) {
            this.setModalOpenFalse();
        }
    }

    async setModalOpenFalse() {
        this.setState(
            {
                modalOpen: false,
            }
        );
        if(typeof this.props.callBack == 'function') {
            await this.props.callBack();
        }
    }

    render() {
        return (
            <Box>
                <Button
                    size={'sm'}
                    color="primary"
                    sx={{mt: 0, mb: 1}}
                    onClick={this.setModalOpenTrue.bind(this)}
                >
                    <AddCircleIcon sx={{mr: 1}}/> Добавить
                </Button>
                <Modal
                    open={this.state.modalOpen}
                >
                    <ModalDialog
                        layout={'center'}
                        size="lg"
                    >
                        <ModalClose
                            onClick={this.setModalOpenFalse.bind(this)}
                        />
                        <Box
                            sx={{
                                overflowY: 'scroll',
                                width: 'calc(80vw)',
                                height: 'calc(80vh)',
                                pr: 2
                            }}
                        >
                            {
                                this.state.open?
                                    <>
                                        <Typography>
                                            Реквизиты добавлены успешно!
                                        </Typography>
                                        <Button
                                            color="success"
                                            sx={{mt: 2}}
                                            onClick={this.setModalOpenFalse.bind(this)}
                                        >
                                            Закрыть вкладку
                                        </Button>
                                    </>
                                :
                                    <>
                                        <Typography
                                            level={'h2'}
                                            sx={{mb: 2}}
                                        >
                                            Добавление реквизитов
                                        </Typography>
                                        <CommonForm
                                            fields={this.state.formFields}
                                            processForm={this.processForm.bind(this)}
                                        />
                                    </>
                            }
                        </Box>
                    </ModalDialog>
                </Modal>
            </Box>
        )
    }
}

export default RequisitesCreateModal