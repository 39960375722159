import React from "react";
import {CardOverflow, CircularProgress} from "@mui/joy";
import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import Link from "@mui/joy/Link";
import CardContent from "@mui/joy/CardContent";
import Card from "@mui/joy/Card";


// components
import BaseApi from "../../utils/BaseApi";
import withNavigate from "../../utils/withNavigate";


class FeatureRequestSearchCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            featureRequest: null,
        }
    }

    async retrieveFeatureRequest() {
        const url = 'feature_request/' + this.props.featureRequestId;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState({
            featureRequest: response.data,
        });
    }

    async componentDidMount() {
        await this.retrieveFeatureRequest();
    }

    render() {
        if (!this.state.featureRequest) {
            return (<CircularProgress/>)
        }
        return (
            <Card
                orientation="horizontal"
                variant="outlined"
                sx={
                    {
                        'm': 2,
                    }
                }
            >
                <CardOverflow
                    variant='soft'
                    color={'primary'}
                    sx={{
                        px: 0.2,
                        writingMode: 'vertical-lr',
                        transform: 'rotate(180deg)',
                        textAlign: 'center',
                        fontSize: 'xs',
                        fontWeight: 'xl',
                        letterSpacing: '1px',
                        textTransform: 'uppercase',
                        borderLeft: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    Тех. поддержка
                </CardOverflow>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid xs={12} md={8} onClick={() => {this.props.callBack()}}>
                            <Typography level={'body2'} sx={{mb: 1}}>Название:</Typography>
                            <Link
                                onClick={() => this.props.navigate(`/feature_request/detail/${this.state.featureRequest.id}`)}
                            >
                                {this.state.featureRequest.title}
                            </Link>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Typography level={'body2'} sx={{mb: 1}}>Автор:</Typography>
                            <Typography>
                                {this.state.featureRequest.owner?.first_name} {this.state.featureRequest.owner?.last_name}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }
}

export default withNavigate(FeatureRequestSearchCard)