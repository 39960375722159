import React from "react";
import Avatar from "@mui/joy/Avatar";
import UserSubscriberContext from "../../context/UserSubscriberContext";

class UserAvatar extends React.Component {
    static contextType = UserSubscriberContext

    render() {
        return(
            <Avatar src={process.env.REACT_APP_API_HOST+this.context.userObject.avatar}/>
        )
    }
}

export default UserAvatar;
