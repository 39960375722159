import React from 'react';
import BaseApi from "../../utils/BaseApi";
import {CircularProgress} from "@mui/joy";
import Box from '@mui/joy/Box';

// components
import withRouter from "../../utils/withRouter";
import CommonPaginatedTable from "../Shared/CommonPaginatedTable";


class EmployeeBonusList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bonuses: [],
            pages: null,
        };
    }

    async componentDidMount() {
        await this.retrieveBonuses();
    }

    async componentDidUpdate(prevProps, prevState, snapshot){
        if (prevProps !== this.props){
            await this.retrieveBonuses();
        }
    }

    async retrieveBonuses() {
        const url = 'employee_bonus/';
        const api = new BaseApi();
        let params = {
            declined: false,
        }
        if (this.props.params.pageNumber){
            params['page'] = this.props.params.pageNumber;
        }
        let response = await api.get(url, params);
        this.setState(
            {
                bonuses: response.data.results,
                pages: response.data.total_pages,
            }
        );
    }

    render() {
        if (!this.state.bonuses) {
            return (<CircularProgress/>)
        }
        if (!this.state.pages) {
            return (<CircularProgress/>)
        }
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    my: 1,
                    gap: 1,
                    flexWrap: 'wrap',
                    '& > *': {
                        minWidth: 'clamp(0px, (500px - 100%) * 999, 100%)',
                        flexGrow: 1,
                    },
                }}
            >
                <CommonPaginatedTable
                    title={'Премии коллег'}
                    url={'employee_bonus'}
                    data={this.state.bonuses}
                    pages={this.state.pages}
                    pageNumber={this.props.params.pageNumber!==undefined?parseInt(this.props.params.pageNumber):1}
                    callBack={this.retrieveBonuses.bind(this)}
                    createComponent={null}
                    fields={[
                        {
                            title: 'Фамилия',
                            name: 'employee.last_name',
                            visible: true,
                        },
                        {
                            title: 'Имя',
                            name: 'employee.first_name',
                            visible: true,
                        },
                        {
                            title: 'Отчество',
                            name: 'employee.patronymic',
                            visible: true,
                        },
                        {
                            title: 'Комментарий премии',
                            name: 'description',
                            visible: true,
                        },
                        {
                            title: 'Сумма',
                            name: 'salary',
                            visible: true,
                        },
                        {
                            title: 'Дата выплаты',
                            name: 'pay_date',
                            visible: true,
                        },
                        {
                            title: 'Согласовано',
                            name: 'approved',
                            visible: true,
                            boolean_negative: 'wait',
                        },
                        {
                            title: 'Создано',
                            name: 'created_on',
                            visible: true,
                        },
                        {
                            title: 'Обновлено',
                            name: 'updated_on',
                            visible: true,
                        },
                    ]
                    }
                />
            </Box>
        )
    }
}

export default withRouter(EmployeeBonusList)
