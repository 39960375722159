import React from 'react';

import Typography from "@mui/joy/Typography";
import Link from "@mui/joy/Link";
import ProfileHorizontalByProps from "../Profile/ProfileHorizontalByProps";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import Box from "@mui/joy/Box";
import withNavigate from "../../utils/withNavigate";


class TaskDetailHeader extends React.Component {
    render() {
        return(
            <Box>
                <Typography
                    level={'title-md'}
                    sx={{'mb': 1}}
                >
                    {this.props.task.title} для&nbsp;
                    <Link onClick={() => this.props.navigate(`/account/detail/${this.props.task.account.id}`)}>
                        {this.props.task.account.nameshort}
                    </Link>
                </Typography>
                {
                    this.props.task.opportunity ?
                    <Typography
                        level={'title-md'}
                        sx={{'mb': 1}}
                    >
                        к сделке&nbsp;
                        <Link onClick={() => this.props.navigate(`/opportunity/detail/${this.props.task.opportunity.id}`)}>
                            {this.props.task.opportunity.short_name}
                        </Link>
                    </Typography>:
                    null
                }
                <Box>
                    <ProfileHorizontalByProps
                        user={this.props.task.owner}
                    />
                    <ArrowRightAltIcon
                        sx={
                            {
                                'mr': 1,
                                'display': 'inline-block',
                                'verticalAlign': 'middle',
                            }
                        }
                    />
                    <ProfileHorizontalByProps
                        user={this.props.task.executor}
                    />
                    <Chip
                        sx={{'mb': 2, 'mt': 2}}
                        color={(dayjs(this.props.task.end_dt)>dayjs())?'success':'error'}
                        label={'до ' + dayjs(this.props.task.end_dt).format('DD.MM.YYYY')}
                    />
                </Box>
            </Box>
        )
    }
}

export default withNavigate(TaskDetailHeader)