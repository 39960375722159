import React from 'react';
import {CircularProgress, Stack, Step, StepButton, StepIndicator, Stepper} from "@mui/joy";
import {Check} from "@mui/icons-material";
import BaseApi from "../../utils/BaseApi";
import CommonForm from "../Shared/CommonForm";
import AttachmentCreate from "../Attachment/AttachmentCreate";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";


class RouteStateCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            steps: [
                {
                    order: 0,
                    title: 'Следующий шаг',
                },
                {
                    order: 1,
                    title: 'Исполнитель',
                },
                {
                    order: 2,
                    title: 'Данные для шага',
                },

            ],
            lockedSteps: [],
            conditions: null,
            total: null,
            stageObj: null,
            stages: this.props.routeState.stage.next_stages,
            operatorObj: null,
            operators: null,
            attachments: null,
        };
    }

    async componentDidMount() {
        let conditions = await this.retrieveConditions();
        let step = this.state.activeStep;
        let operator;
        let stage;
        let attachments;
        let lockedSteps = this.state.lockedSteps;
        if (this.props.routeState.stage.promotion_logic === 'ITERATIVE'){
            if (!this.props.routeState.operator.parent) {
                step = 0;
            }
            else {
                step = 2;
                operator = this.props.routeState.operator.parent;
                stage = this.props.routeState.stage;
                lockedSteps = [0,1];
                attachments = await this.retrieveAttachments(
                    this.props.routeState.stage.attachment_xtype
                );
            }
        }
        if (this.state.stages.length === 0) {
            step = 2;
        }
        await this.setState(
            {
                activeStep: step,
                operatorObj: operator,
                stageObj: stage,
                lockedSteps: lockedSteps,
                attachments: attachments,
                ...conditions
            }
        )
    }

    async retrieveOperators(operator, logics) {
        let url = '';
        switch (logics){
            case 'NORMAL':
                url = `subscriber/`;
                break;
            case 'TREELIMITED':
                url = `subscriber/${this.props.systemOwner.id}/drilldown/`;
                break;
            default:
                return []
        }
        const params = {
            active: true,
            subscriber_type: operator.id,
        }
        const api = new BaseApi();
        let response = await api.get(
            url,
            params
        );
        return (
            response.data.results
        )
    }

    async retrieveStage(id) {
        const url = `route_stage/${id}`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        return (
            response.data
        )
    }

    async retrieveOperator(id) {
        const url = `subscriber/${id}`;
        const api = new BaseApi();
        let response = await api.get(
            url,
            {}
        );
        return (
            response.data
        )
    }

    async retrieveConditions() {
        const url = `route_state/${this.props.routeState.id}/conditions/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        let obj = {
            conditions: response.data.conditions,
            total: response.data.total
        }
        return obj
    }

    async retrieveAttachments(xtype){
        this.setState(
            {
                attachments: null
            }
        )
        const url = `attachment/`;
        const api = new BaseApi();
        const params = {
            deleted_bool: false,
            related_app: this.props.entity,
            related_app_id: this.props.entity_id,
            xtype: xtype,
        }
        let response = await api.get(
            url,
            params
        );
        return response.data.results
    }

    async processStep0(form, event) {
        let step;
        let operators;
        let operatorObj;
        let lockedSteps = this.state.lockedSteps;
        let stage = form.state.formData.get('stage');
        let stageObj = await this.retrieveStage(stage);

        if (stageObj.promotion_logic === 'SYSTEMOWNER'){
            step = 2;
            operatorObj = this.props.systemOwner;
            lockedSteps = [1, ]
        }
        else {
            operators = await this.retrieveOperators(
                stageObj.operator,
                stageObj.promotion_logic,
            );
            step = 1;
        }
        let attachments = await this.retrieveAttachments(
            stageObj.attachment_xtype
        );
        await this.setState(
            {
                activeStep: step,
                stageObj: stageObj,
                operators: operators,
                attachments: attachments,
                operatorObj: operatorObj,
                lockedSteps: lockedSteps,
            }
        );
    }

    async processStep1(form, event) {
        let operator = form.state.formData.get('operator');
        let operatorObj = await this.retrieveOperator(
            operator
        );
        await this.setState(
            {
                activeStep: 2,
                operatorObj: operatorObj,
            }
        );
    }

    async processStep2(form, event) {
        let formDataObj = Object.fromEntries(form.state.formData.entries());
        if (this.state.operatorObj){
            formDataObj['operator'] = this.state.operatorObj.id;
        }
        if (this.state.stageObj){
            formDataObj['stage'] = this.state.stageObj.id;
        }
        formDataObj['route'] = this.props.routeState.route;
        if (typeof(form.state.formData.attachments) !== 'undefined') {
            formDataObj.attachments = form.state.formData.attachments.split(',')
        }
        else {
            formDataObj.attachments = []
        }
        const url = `route_state/`;
        const api = new BaseApi();
        let response = await api.post(
            url,
            formDataObj,
        );
        if (response.status === 201) {
            await this.props.callBack();
        }
    }

    render() {
        let stepper = (
            <Stepper sx={{width: '100%'}}>
                {this.state.steps.map((step, index) => (
                    <Step
                        key={step.order}
                        onClick={
                            () => {
                                if(this.state.activeStep >= index && this.state.lockedSteps.indexOf(index) !== -1){
                                    this.setState({activeStep: index})
                                }
                            }
                        }
                        indicator={
                            <StepIndicator
                                variant={'solid'}
                                color={this.state.activeStep < index ? 'neutral' : 'success'}
                            >
                                {this.state.activeStep <= index ? index + 1 : <Check/>}
                            </StepIndicator>
                        }
                        sx={{
                            '&::after': {
                                ...(this.state.activeStep > index &&
                                    index !== 2 && {bgcolor: 'primary.solidBg'}),
                            },
                        }}
                    >
                        <StepButton>{step.title}</StepButton>
                    </Step>

                ))}
            </Stepper>
        );
        if (this.state.conditions === null){
            return (<CircularProgress/>)
        }
        if (!this.state.total){
            return (
                <Card
                    color={'warning'}
                    variant="soft"
                >
                    <CardContent>
                        <Typography
                            level={'body-md'}
                        >
                            Требуется выполнить все условия стадии
                        </Typography>
                    </CardContent>
                </Card>
            )
        }
        if (this.state.activeStep === 0){
            return(
                <Card
                    color={'success'}
                    variant={'soft'}
                >
                    {stepper}
                    <CommonForm
                        key={'step-0-form'}
                        fields={[
                            {
                                name: 'stage',
                                label: 'Стадия',
                                xs: 12,
                                sm: null,
                                md: null,
                                lg: null,
                                xl: null,
                                required: true,
                                default: null,
                                type: 'radio',
                                options: this.state.stages.map((each) => {return(
                                    {'value': each.id, 'label': each.title}
                                )})
                            },
                            {
                                type: 'submit',
                                label: 'Далее',
                                color: 'success',
                            }
                        ]}
                        object={null}
                        processForm={this.processStep0.bind(this)}
                    />
                </Card>
            )
        }
        else if (this.state.activeStep === 1) {
            return(
                <Stack
                    spacing={2}
                >
                    <Card
                        color={'primary'}
                        variant={'soft'}
                    >
                        <CardContent>
                            <Typography>
                                <CheckCircleIcon
                                    color={'primary'}
                                    sx={{
                                        display: 'inline',
                                        mr: 1,
                                    }}
                                />
                                Стадия: {this.state.stageObj.title}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card
                        color={'success'}
                        variant={'soft'}
                    >
                        {stepper}
                        <CommonForm
                            key={'step-1-form'}
                            fields={[
                                {
                                    name: 'operator',
                                    label: 'Исполнитель',
                                    xs: 12,
                                    sm: null,
                                    md: null,
                                    lg: null,
                                    xl: null,
                                    required: true,
                                    default: null,
                                    type: 'radio',
                                    options: this.state.operators.map((each) => {return(
                                        {
                                            'value': each.id,
                                            'label': `${each.user.first_name} ${each.user.last_name}`
                                        }
                                    )})
                                },
                                {
                                    type: 'submit',
                                    label: 'Далее',
                                    color: 'success',
                                }
                            ]}
                            object={null}
                            processForm={this.processStep1.bind(this)}
                        />
                    </Card>
                </Stack>
            )
        }
        else if (this.state.activeStep === 2) {
            return(
                <Stack
                    spacing={2}
                >
                    <Card
                        color={'primary'}
                        variant={'soft'}
                    >

                        <CardContent>
                            <Typography>
                                <CheckCircleIcon
                                    color={'primary'}
                                    sx={{
                                        display: 'inline',
                                        mr: 1,
                                    }}
                                />
                                Исполнитель: {this.state.operatorObj.user.first_name} {this.state.operatorObj.user.last_name}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card
                        color={'primary'}
                        variant={'soft'}
                    >
                        <CardContent>
                            <Typography>
                                <CheckCircleIcon
                                    color={'primary'}
                                    sx={{
                                        display: 'inline',
                                        mr: 1,
                                    }}
                                />
                                Стадия: {this.state.stageObj.title}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card
                        color={'success'}
                        variant={'soft'}
                    >
                        {stepper}
                        <AttachmentCreate
                            appId={this.props.entity_id}
                            appName={this.props.entity}
                            callBack={this.retrieveAttachments.bind(this)}
                        />
                        <CommonForm
                            key={'step-2-form'}
                            fields={[
                                {
                                    name: 'attachments',
                                    label: 'Вложения',
                                    xs: 12,
                                    sm: null,
                                    md: null,
                                    lg: null,
                                    xl: null,
                                    required: this.state.stage?this.state.stage.is_attach_needed:false,
                                    default: [],
                                    type: 'multiselect',
                                    options: this.state.attachments.map((each) => {return(
                                        {'value': each.id, 'label': each.uploadname}
                                    )})
                                },
                                {
                                    name: 'note',
                                    label: 'Комментарий',
                                    xs: 12,
                                    sm: null,
                                    md: null,
                                    lg: null,
                                    xl: null,
                                    required: true,
                                    default: null,
                                    type: 'textarea',
                                },
                                {
                                    type: 'submit',
                                    label: 'Продвинуть',
                                    color: 'success',
                                }
                            ]}
                            object={null}
                            processForm={this.processStep2.bind(this)}
                        />
                    </Card>
                </Stack>
            )
        }
        return (
            <></>
        )
    }
}

export default RouteStateCreate