import React from 'react';

import BaseApi from "../../utils/BaseApi";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import UserSubscriberContext from "../../context/UserSubscriberContext";
import ProfileHorizontalByProps from "../Profile/ProfileHorizontalByProps";
import {Stack} from "@mui/joy";

// icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import retrievePermission from "../../utils/retrievePermission";

class QualificatorApprove extends React.Component {
    static contextType = UserSubscriberContext

    constructor(props) {
        super(props);
        this.state = {
            qualificator: this.props.qualificator,
            permission: null,
            subscriber: null,
            user: null,
        };
    }

    async componentDidMount() {
        await this.retrievePermission();
    }

    async approveQualificator() {
        const url = `qualificator/${this.state.qualificator.id}/approve/`;
        const api = new BaseApi();
        await api.get(
            url,
            {}
        );
        await this.props.callBack()
    }

    async retrievePermission() {
        let p = await retrievePermission(
            'QualificatorViewSet',
            'approve',
            this.context.subscriberObject
        );
        await this.setState(
            {
                permission: p
            }
        );
    }

    render() {
        if (this.state.qualificator.approved){
            return(
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    sx={{mt: 2}}
                >
                    <CheckCircleIcon color={'success'} />
                    <Typography level={'body-md'}>
                        Подтвержден
                    </Typography>
                    <ProfileHorizontalByProps
                         user={this.state.qualificator.approved_by}
                    />
                    <Typography level={'body-md'}>
                        {this.state.qualificator.approved_on}
                    </Typography>
                </Stack>
            )
        }
        else {
            if (this.state.permission > 0){
                return (
                    <Button
                        color={'success'}
                        onClick={this.approveQualificator.bind(this)}
                        sx={{mt: 2}}
                    >
                        Подтвердить
                    </Button>
                )
            }
            else {
                return (
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        sx={{mt: 2}}
                    >
                        <WarningIcon color={'warning'} />
                        <Typography level={'body-md'}>
                            Требует подтверждения
                        </Typography>
                    </Stack>
                )
            }
        }
    }
}

export default QualificatorApprove