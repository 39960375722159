import React from "react";
import BaseApi from "../../utils/BaseApi";
import {CircularProgress, Table} from "@mui/joy";
import {TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import ProfileHorizontalByProps from "../Profile/ProfileHorizontalByProps";
import Typography from "@mui/joy/Typography";



class EvaluationTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            evaluation: null,
        };
    }

    async componentDidMount() {
        await this.retrieveEvaluation();
    }

    async retrieveEvaluation() {
        const url = `opportunity/${this.props.opportunityId}/evaluation/`;
        const api = new BaseApi();
        let response = await api.get(url, {});
        this.setState(
            {
                evaluation: response.data
            }
        );
    }

    render() {
        if (!this.state.evaluation) {
            return (<CircularProgress/>)
        }
        return (
            <>
                <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    hoverRow
                    sx={{
                        '--TableCell-headBackground': (theme) =>
                        theme.vars.palette.background.level1,
                        '--Table-headerUnderlineThickness': '1px',
                        '--TableRow-hoverBackground': (theme) =>
                        theme.vars.palette.background.level1,
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Сотрудник
                            </TableCell>
                            <TableCell>
                                Загрузка план<br/>(в трудоемости + вне)
                            </TableCell>
                            <TableCell>
                                Загрузка факт<br/>(в трудоемости + вне)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.evaluation.user_workloads.map((row) => (
                            <TableRow key={row.user.id}>
                                <TableCell>
                                    <ProfileHorizontalByProps
                                        user={row.user}
                                    />
                                </TableCell>
                                <TableCell>
                                    {row.workload_plan_estimation} + {row.workload_plan_not_estimation}
                                </TableCell>
                                <TableCell>
                                    {row.workload_fact_estimation} + {row.workload_fact_not_estimation}
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow key={'totals'}>
                            <TableCell>
                                <strong>Итого</strong>
                            </TableCell>
                            <TableCell>
                                <strong>{this.state.evaluation.total_workloads.workload_plan_estimation} + {this.state.evaluation.total_workloads.workload_plan_not_estimation}</strong>
                            </TableCell>
                            <TableCell>
                                <strong>{this.state.evaluation.total_workloads.workload_fact_estimation} + {this.state.evaluation.total_workloads.workload_fact_not_estimation}</strong>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Typography
                    sx={{
                        mt: 2
                    }}
                >
                    <strong>
                        Ожидаемая трудоемкость: {this.state.evaluation.total_workloads.max_proba_estimation}
                    </strong>
                </Typography>
            </>
        )
    }
}

export default EvaluationTab